import constants from "../constants/constants.json";
import {
  MultiStoreControlConfig,
  MultiStoreSingleProductReq,
} from "../interfaces/IControlConfig";
import { IControlConfigRequest } from "../interfaces/IControlConfigRequest";
import { formServiceUrl } from "../utils/apiUrlUtil";
import { createApiClient } from "./apiClient";

const {
  urlConstants: { priceViewMaster },
} = constants;

const {
  getControlConfigsForStoreList,
  getControlConfigsForStoreListV2,
  name: SERVICE_NAME,
} = priceViewMaster;

const baseUrlFactory = async () => formServiceUrl(SERVICE_NAME);
const apiClient = createApiClient(baseUrlFactory);

export const getMultiStoreSingleProductControl = async (
  reqPayload: MultiStoreSingleProductReq
): Promise<MultiStoreControlConfig> => {
  return await apiClient.post<MultiStoreControlConfig>(
    getControlConfigsForStoreList,
    reqPayload
  );
};

export const getMultiStoreSingleProductControlV2 = async (
  reqPayload: IControlConfigRequest
): Promise<MultiStoreControlConfig> => {
  return await apiClient.post<MultiStoreControlConfig>(
    getControlConfigsForStoreListV2,
    reqPayload
  );
};
