import { MRT_ColumnDef } from "material-react-table";

interface MinMaxPreviewRowData {
  storeId: string;
  productId: string;
  onFailAction: {
    before: any;
    after: any;
  };
  maxPrice: {
    before: any;
    after: any;
  };
  minPrice: {
    before: any;
    after: any;
  };
  enabled: {
    before: any;
    after: any;
  };
}

interface MaxIncDecPreviewRowData {
  storeId: string;
  productId: string;
  onFailAction: {
    before: any;
    after: any;
  };
  maxIncrease: {
    before: any;
    after: any;
  };
  maxDecrease: {
    before: any;
    after: any;
  };
  enabled: {
    before: any;
    after: any;
  };
}

interface AutoApprovalPreviewRowData {
  storeId: string;
  productId: string;
  autoApprove: {
    before: any;
    after: any;
  };
}

interface OpisAgePreviewRowData {
  storeId: string;
  productId: string;
  opisAge: {
    before: any;
    after: any;
  };
  enabled: {
    before: any;
    after: any;
  };
  onFailAction: {
    before: any;
    after: any;
  };
}

interface LastDigitNinePreviewRowData {
  storeId: string;
  productId: string;
  enabled: {
    before: any;
    after: any;
  };
  onFailAction: {
    before: any;
    after: any;
  };
}

interface CompBoundaryPreviewRowData {
  storeId: string;
  productId: string;
  opisId: string;
  lowerThreshold: {
    before: any;
    after: any;
  };
  upperThreshold: {
    before: any;
    after: any;
  };
}

const minMaxChangesPreviewTableCols: MRT_ColumnDef<MinMaxPreviewRowData>[] = [
  {
    header: "Store",
    accessorKey: "storeId",
  },
  {
    header: "Product",
    accessorKey: "productId",
    size: 150,
  },
  {
    header: "Min Price Before",
    size: 200,
    filterVariant: "text",
    accessorFn: (row) =>
      row?.minPrice?.before ? row?.minPrice?.before : "N/A",
    Cell: ({ row }) => (
      <div
        style={{
          display: "flex", // Flexbox for centering content
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          width: "100%", // Ensure div takes the full width of the cell
          height: "100%", // Ensure div takes the full height of the cell
          color: row.original?.minPrice?.before ? "black" : "grey",
          textAlign: "center", // Center the text within the div
        }}
      >
        {row.original?.minPrice?.before
          ? row.original?.minPrice?.before
          : "N/A"}
      </div>
    ),
  },
  {
    header: "Min Price After",
    size: 200,
    accessorFn: (row) => (row?.minPrice?.after ? row?.minPrice?.after : "N/A"),
    Cell: ({ row }) => {
      return (
        <div
          style={{
            display: "flex", // Flexbox for centering content
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            width: "100%", // Ensure div takes the full width of the cell
            height: "100%", // Ensure div takes the full height of the cell
            textAlign: "center", // Center the text within the div
            color: row.original?.minPrice?.after ? "orange" : "gray",
            fontWeight: row.original?.minPrice?.after ? "bold" : "normal",
          }}
        >
          {row.original?.minPrice?.after
            ? row.original?.minPrice?.after
            : "N/A"}
        </div>
      );
    },
  },
  {
    header: "Max Price Before",
    size: 200,
    accessorFn: (row) =>
      row?.maxPrice?.before ? row?.maxPrice?.before : "N/A",
    Cell: ({ row }) => (
      <div
        style={{
          display: "flex", // Flexbox for centering content
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          width: "100%", // Ensure div takes the full width of the cell
          height: "100%", // Ensure div takes the full height of the cell
          color: row.original?.maxPrice?.before ? "black" : "grey",
          textAlign: "center", // Center the text within the div
        }}
      >
        {row.original?.maxPrice?.before
          ? row.original?.maxPrice?.before
          : "N/A"}
      </div>
    ),
  },
  {
    header: "Max Price After",
    size: 200,
    accessorFn: (row) => (row?.maxPrice?.after ? row?.maxPrice?.after : "N/A"),
    Cell: ({ row }) => {
      return (
        <div
          style={{
            display: "flex", // Flexbox for centering content
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            width: "100%", // Ensure div takes the full width of the cell
            height: "100%", // Ensure div takes the full height of the cell
            textAlign: "center", // Center the text within the div
            color: row.original?.maxPrice?.after ? "orange" : "gray",
            fontWeight: row.original?.maxPrice?.after ? "bold" : "normal",
          }}
        >
          {row.original?.maxPrice?.after
            ? row.original?.maxPrice?.after
            : "N/A"}
        </div>
      );
    },
  },
  {
    header: "Failure Action Before",
    size: 250,
    accessorFn: (row) =>
      row?.onFailAction?.before ? row?.onFailAction?.before : "N/A",
    Cell: ({ row }) => (
      <div
        style={{
          display: "flex", // Flexbox for centering content
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          width: "100%", // Ensure div takes the full width of the cell
          height: "100%", // Ensure div takes the full height of the cell
          color: row.original?.onFailAction?.before ? "black" : "grey",
          textAlign: "center", // Center the text within the div
        }}
      >
        {row.original?.onFailAction?.before
          ? row.original?.onFailAction?.before
          : "N/A"}
      </div>
    ),
  },
  {
    header: "Failure Action After",
    size: 250,
    accessorFn: (row) =>
      row?.onFailAction?.after ? row?.onFailAction?.after : "N/A",
    Cell: ({ row }) => {
      return (
        <div
          style={{
            display: "flex", // Flexbox for centering content
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            width: "100%", // Ensure div takes the full width of the cell
            height: "100%", // Ensure div takes the full height of the cell
            textAlign: "center", // Center the text within the div
            color: row.original?.onFailAction?.after ? "orange" : "gray",
            fontWeight: row.original?.onFailAction?.after ? "bold" : "normal",
          }}
        >
          {row.original?.onFailAction?.after
            ? row.original?.onFailAction?.after
            : "N/A"}
        </div>
      );
    },
  },
  {
    header: "Enabled Flag Before",
    size: 200,
    accessorFn: (row) => (row?.enabled?.after ? row?.enabled?.after : "N/A"),
    Cell: ({ row }) => (
      <div
        style={{
          display: "flex", // Flexbox for centering content
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          width: "100%", // Ensure div takes the full width of the cell
          height: "100%", // Ensure div takes the full height of the cell
          color: row.original?.enabled?.before ? "black" : "grey",
          textAlign: "center", // Center the text within the div
        }}
      >
        {row.original?.enabled?.before ? row.original?.enabled?.before : "N/A"}
      </div>
    ),
  },
  {
    header: "Enabled Flag After",
    size: 200,
    accessorFn: (row) => (row?.enabled?.after ? row?.enabled?.after : "N/A"),
    Cell: ({ row }) => {
      return (
        <div
          style={{
            display: "flex", // Flexbox for centering content
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            width: "100%", // Ensure div takes the full width of the cell
            height: "100%", // Ensure div takes the full height of the cell
            textAlign: "center", // Center the text within the div
            color: row.original?.enabled?.after ? "orange" : "gray",
            fontWeight: row.original?.enabled?.after ? "bold" : "normal",
          }}
        >
          {row.original?.enabled?.after ? row.original?.enabled?.after : "N/A"}
        </div>
      );
    },
  },
];

const maxIncDecChangesPreviewTableCols: MRT_ColumnDef<MaxIncDecPreviewRowData>[] =
  [
    {
      header: "Store",
      accessorKey: "storeId",
    },
    {
      header: "Product",
      accessorKey: "productId",
      size: 150,
    },
    {
      header: "Max Increase Before",
      size: 200,
      filterVariant: "text",
      accessorFn: (row) => row?.maxIncrease?.before ?? "N/A",
      Cell: ({ row }) => (
        <div
          style={{
            display: "flex", // Flexbox for centering content
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            width: "100%", // Ensure div takes the full width of the cell
            height: "100%", // Ensure div takes the full height of the cell
            color:
              row.original?.maxIncrease?.before ?? false ? "black" : "grey",
            textAlign: "center", // Center the text within the div
          }}
        >
          {row.original?.maxIncrease?.before ?? "N/A"}
        </div>
      ),
    },
    {
      header: "Max Increase After",
      size: 200,
      accessorFn: (row) => row?.maxIncrease?.after ?? "N/A",
      Cell: ({ row }) => {
        return (
          <div
            style={{
              display: "flex", // Flexbox for centering content
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically
              width: "100%", // Ensure div takes the full width of the cell
              height: "100%", // Ensure div takes the full height of the cell
              textAlign: "center", // Center the text within the div
              color:
                row.original?.maxIncrease?.after !== undefined &&
                row.original?.maxIncrease?.after !== null
                  ? "orange"
                  : "gray",
              fontWeight:
                row.original?.maxIncrease?.after !== undefined &&
                row.original?.maxIncrease?.after !== null
                  ? "bold"
                  : "normal",
            }}
          >
            {row.original?.maxIncrease?.after ?? "N/A"}
          </div>
        );
      },
    },
    {
      header: "Max Decrease Before",
      size: 200,
      accessorFn: (row) => row?.maxDecrease?.before ?? "N/A",
      Cell: ({ row }) => (
        <div
          style={{
            display: "flex", // Flexbox for centering content
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            width: "100%", // Ensure div takes the full width of the cell
            height: "100%", // Ensure div takes the full height of the cell
            color:
              row.original?.maxDecrease?.before ?? false ? "black" : "grey",
            textAlign: "center", // Center the text within the div
          }}
        >
          {row.original?.maxDecrease?.before ?? "N/A"}
        </div>
      ),
    },
    {
      header: "Max Decrease After",
      size: 200,
      accessorFn: (row) => row?.maxDecrease?.after ?? "N/A",
      Cell: ({ row }) => {
        return (
          <div
            style={{
              display: "flex", // Flexbox for centering content
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically
              width: "100%", // Ensure div takes the full width of the cell
              height: "100%", // Ensure div takes the full height of the cell
              textAlign: "center", // Center the text within the div
              color:
                row.original?.maxDecrease?.after !== undefined &&
                row.original?.maxDecrease?.after !== null
                  ? "orange"
                  : "gray",
              fontWeight:
                row.original?.maxDecrease?.after !== undefined &&
                row.original?.maxDecrease?.after !== null
                  ? "bold"
                  : "normal",
            }}
          >
            {row.original?.maxDecrease?.after ?? "N/A"}
          </div>
        );
      },
    },
    {
      header: "Failure Action Before",
      size: 250,
      accessorFn: (row) =>
        row?.onFailAction?.before ? row?.onFailAction?.before : "N/A",
      Cell: ({ row }) => (
        <div
          style={{
            display: "flex", // Flexbox for centering content
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            width: "100%", // Ensure div takes the full width of the cell
            height: "100%", // Ensure div takes the full height of the cell
            color: row.original?.onFailAction?.before ? "black" : "grey",
            textAlign: "center", // Center the text within the div
          }}
        >
          {row.original?.onFailAction?.before
            ? row.original?.onFailAction?.before
            : "N/A"}
        </div>
      ),
    },
    {
      header: "Failure Action After",
      size: 250,
      accessorFn: (row) =>
        row?.onFailAction?.after ? row?.onFailAction?.after : "N/A",
      Cell: ({ row }) => {
        return (
          <div
            style={{
              display: "flex", // Flexbox for centering content
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically
              width: "100%", // Ensure div takes the full width of the cell
              height: "100%", // Ensure div takes the full height of the cell
              textAlign: "center", // Center the text within the div
              color: row.original?.onFailAction?.after ? "orange" : "gray",
              fontWeight: row.original?.onFailAction?.after ? "bold" : "normal",
            }}
          >
            {row.original?.onFailAction?.after
              ? row.original?.onFailAction?.after
              : "N/A"}
          </div>
        );
      },
    },
    {
      header: "Enabled Flag Before",
      size: 200,
      accessorFn: (row) => (row?.enabled?.after ? row?.enabled?.after : "N/A"),
      Cell: ({ row }) => (
        <div
          style={{
            display: "flex", // Flexbox for centering content
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            width: "100%", // Ensure div takes the full width of the cell
            height: "100%", // Ensure div takes the full height of the cell
            color: row.original?.enabled?.before ? "black" : "grey",
            textAlign: "center", // Center the text within the div
          }}
        >
          {row.original?.enabled?.before
            ? row.original?.enabled?.before
            : "N/A"}
        </div>
      ),
    },
    {
      header: "Enabled Flag After",
      size: 200,
      accessorFn: (row) => (row?.enabled?.after ? row?.enabled?.after : "N/A"),
      Cell: ({ row }) => {
        return (
          <div
            style={{
              display: "flex", // Flexbox for centering content
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically
              width: "100%", // Ensure div takes the full width of the cell
              height: "100%", // Ensure div takes the full height of the cell
              textAlign: "center", // Center the text within the div
              color: row.original?.enabled?.after ? "orange" : "gray",
              fontWeight: row.original?.enabled?.after ? "bold" : "normal",
            }}
          >
            {row.original?.enabled?.after
              ? row.original?.enabled?.after
              : "N/A"}
          </div>
        );
      },
    },
  ];

const autoApprovalChangesPreviewTableCols: MRT_ColumnDef<AutoApprovalPreviewRowData>[] =
  [
    {
      header: "Store",
      accessorKey: "storeId",
    },
    {
      header: "Product",
      accessorKey: "productId",
      size: 150,
    },
    {
      header: "Auto Approval Flag Before",
      size: 200,
      accessorFn: (row) =>
        row?.autoApprove?.after ? row?.autoApprove?.after : "N/A",
      Cell: ({ row }) => (
        <div
          style={{
            display: "flex", // Flexbox for centering content
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            width: "100%", // Ensure div takes the full width of the cell
            height: "100%", // Ensure div takes the full height of the cell
            color: row.original?.autoApprove?.before ? "black" : "grey",
            textAlign: "center", // Center the text within the div
          }}
        >
          {row.original?.autoApprove?.before
            ? row.original?.autoApprove?.before
            : "N/A"}
        </div>
      ),
    },
    {
      header: "Auto Approval Flag After",
      size: 200,
      accessorFn: (row) =>
        row?.autoApprove?.after ? row?.autoApprove?.after : "N/A",
      Cell: ({ row }) => {
        return (
          <div
            style={{
              display: "flex", // Flexbox for centering content
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically
              width: "100%", // Ensure div takes the full width of the cell
              height: "100%", // Ensure div takes the full height of the cell
              textAlign: "center", // Center the text within the div
              color: row.original?.autoApprove?.after ? "orange" : "gray",
              fontWeight: row.original?.autoApprove?.after ? "bold" : "normal",
            }}
          >
            {row.original?.autoApprove?.after
              ? row.original?.autoApprove?.after
              : "N/A"}
          </div>
        );
      },
    },
  ];

const opisAgeChangesPreviewTableCols: MRT_ColumnDef<OpisAgePreviewRowData>[] = [
  {
    header: "Store",
    accessorKey: "storeId",
  },
  {
    header: "Product",
    accessorKey: "productId",
    size: 150,
  },
  {
    header: "Opis Age Before",
    size: 200,
    accessorFn: (row) => row?.opisAge?.before ?? "N/A",
    Cell: ({ row }) => (
      <div
        style={{
          display: "flex", // Flexbox for centering content
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          width: "100%", // Ensure div takes the full width of the cell
          height: "100%", // Ensure div takes the full height of the cell
          color: row.original?.opisAge?.before ?? false ? "black" : "grey",
          textAlign: "center", // Center the text within the div
        }}
      >
        {row.original?.opisAge?.before ?? "N/A"}
      </div>
    ),
  },
  {
    header: "Opis Age After",
    size: 200,
    accessorFn: (row) => row?.opisAge?.after ?? "N/A",
    Cell: ({ row }) => {
      return (
        <div
          style={{
            display: "flex", // Flexbox for centering content
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            width: "100%", // Ensure div takes the full width of the cell
            height: "100%", // Ensure div takes the full height of the cell
            textAlign: "center", // Center the text within the div
            color:
              row.original?.opisAge?.after !== undefined &&
              row.original?.opisAge?.after !== null
                ? "orange"
                : "gray",
            fontWeight:
              row.original?.opisAge?.after !== undefined &&
              row.original?.opisAge?.after !== null
                ? "bold"
                : "normal",
          }}
        >
          {row.original?.opisAge?.after ?? "N/A"}
        </div>
      );
    },
  },
  {
    header: "Enabled Flag Before",
    size: 200,
    accessorFn: (row) => (row?.enabled?.after ? row?.enabled?.after : "N/A"),
    Cell: ({ row }) => (
      <div
        style={{
          display: "flex", // Flexbox for centering content
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          width: "100%", // Ensure div takes the full width of the cell
          height: "100%", // Ensure div takes the full height of the cell
          color: row.original?.enabled?.before ? "black" : "grey",
          textAlign: "center", // Center the text within the div
        }}
      >
        {row.original?.enabled?.before ? row.original?.enabled?.before : "N/A"}
      </div>
    ),
  },
  {
    header: "Enabled Flag After",
    size: 200,
    accessorFn: (row) => (row?.enabled?.after ? row?.enabled?.after : "N/A"),
    Cell: ({ row }) => {
      return (
        <div
          style={{
            display: "flex", // Flexbox for centering content
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            width: "100%", // Ensure div takes the full width of the cell
            height: "100%", // Ensure div takes the full height of the cell
            textAlign: "center", // Center the text within the div
            color: row.original?.enabled?.after ? "orange" : "gray",
            fontWeight: row.original?.enabled?.after ? "bold" : "normal",
          }}
        >
          {row.original?.enabled?.after ? row.original?.enabled?.after : "N/A"}
        </div>
      );
    },
  },
  {
    header: "Failure Action Before",
    size: 250,
    accessorFn: (row) =>
      row?.onFailAction?.before ? row?.onFailAction?.before : "N/A",
    Cell: ({ row }) => (
      <div
        style={{
          display: "flex", // Flexbox for centering content
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          width: "100%", // Ensure div takes the full width of the cell
          height: "100%", // Ensure div takes the full height of the cell
          color: row.original?.onFailAction?.before ? "black" : "grey",
          textAlign: "center", // Center the text within the div
        }}
      >
        {row.original?.onFailAction?.before
          ? row.original?.onFailAction?.before
          : "N/A"}
      </div>
    ),
  },
  {
    header: "Failure Action After",
    size: 250,
    accessorFn: (row) =>
      row?.onFailAction?.after ? row?.onFailAction?.after : "N/A",
    Cell: ({ row }) => {
      return (
        <div
          style={{
            display: "flex", // Flexbox for centering content
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            width: "100%", // Ensure div takes the full width of the cell
            height: "100%", // Ensure div takes the full height of the cell
            textAlign: "center", // Center the text within the div
            color: row.original?.onFailAction?.after ? "orange" : "gray",
            fontWeight: row.original?.onFailAction?.after ? "bold" : "normal",
          }}
        >
          {row.original?.onFailAction?.after
            ? row.original?.onFailAction?.after
            : "N/A"}
        </div>
      );
    },
  },
];

const lastDigitNineChangesPreviewTableCols: MRT_ColumnDef<LastDigitNinePreviewRowData>[] =
  [
    {
      header: "Store",
      accessorKey: "storeId",
    },
    {
      header: "Product",
      accessorKey: "productId",
      size: 150,
    },
    {
      header: "Enabled Flag Before",
      size: 200,
      accessorFn: (row) => (row?.enabled?.after ? row?.enabled?.after : "N/A"),
      Cell: ({ row }) => (
        <div
          style={{
            display: "flex", // Flexbox for centering content
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            width: "100%", // Ensure div takes the full width of the cell
            height: "100%", // Ensure div takes the full height of the cell
            color: row.original?.enabled?.before ? "black" : "grey",
            textAlign: "center", // Center the text within the div
          }}
        >
          {row.original?.enabled?.before
            ? row.original?.enabled?.before
            : "N/A"}
        </div>
      ),
    },
    {
      header: "Enabled Flag After",
      size: 200,
      accessorFn: (row) => (row?.enabled?.after ? row?.enabled?.after : "N/A"),
      Cell: ({ row }) => {
        return (
          <div
            style={{
              display: "flex", // Flexbox for centering content
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically
              width: "100%", // Ensure div takes the full width of the cell
              height: "100%", // Ensure div takes the full height of the cell
              textAlign: "center", // Center the text within the div
              color: row.original?.enabled?.after ? "orange" : "gray",
              fontWeight: row.original?.enabled?.after ? "bold" : "normal",
            }}
          >
            {row.original?.enabled?.after
              ? row.original?.enabled?.after
              : "N/A"}
          </div>
        );
      },
    },
    {
      header: "Failure Action Before",
      size: 250,
      accessorFn: (row) =>
        row?.onFailAction?.before ? row?.onFailAction?.before : "N/A",
      Cell: ({ row }) => (
        <div
          style={{
            display: "flex", // Flexbox for centering content
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            width: "100%", // Ensure div takes the full width of the cell
            height: "100%", // Ensure div takes the full height of the cell
            color: row.original?.onFailAction?.before ? "black" : "grey",
            textAlign: "center", // Center the text within the div
          }}
        >
          {row.original?.onFailAction?.before
            ? row.original?.onFailAction?.before
            : "N/A"}
        </div>
      ),
    },
    {
      header: "Failure Action After",
      size: 250,
      accessorFn: (row) =>
        row?.onFailAction?.after ? row?.onFailAction?.after : "N/A",
      Cell: ({ row }) => {
        return (
          <div
            style={{
              display: "flex", // Flexbox for centering content
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically
              width: "100%", // Ensure div takes the full width of the cell
              height: "100%", // Ensure div takes the full height of the cell
              textAlign: "center", // Center the text within the div
              color: row.original?.onFailAction?.after ? "orange" : "gray",
              fontWeight: row.original?.onFailAction?.after ? "bold" : "normal",
            }}
          >
            {row.original?.onFailAction?.after
              ? row.original?.onFailAction?.after
              : "N/A"}
          </div>
        );
      },
    },
  ];

const compBoundaryPreviewTableCols: MRT_ColumnDef<CompBoundaryPreviewRowData>[] =
  [
    {
      header: "Store",
      accessorKey: "storeId",
    },
    {
      header: "Opis Id",
      accessorKey: "opisId",
      size: 150,
    },
    {
      header: "Product",
      accessorKey: "productId",
      size: 150,
    },
    {
      header: "Min Boundary Before",
      size: 200,
      accessorFn: (row) =>
        row?.lowerThreshold && Object.keys(row.lowerThreshold).length === 0
          ? "NO CHANGE"
          : row?.lowerThreshold?.before ?? "N/A",
      Cell: ({ row }) => (
        <div
          style={{
            display: "flex", // Flexbox for centering content
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            width: "100%", // Ensure div takes the full width of the cell
            height: "100%", // Ensure div takes the full height of the cell
            color:
              row.original?.lowerThreshold &&
              Object.keys(row.original.lowerThreshold).length === 0
                ? "grey"
                : row.original?.lowerThreshold?.before ?? "black",
            textAlign: "center", // Center the text within the div
          }}
        >
          {row.original?.lowerThreshold &&
          Object.keys(row.original.lowerThreshold).length === 0
            ? "NO CHANGE"
            : row.original?.lowerThreshold?.before ?? "N/A"}
        </div>
      ),
    },
    {
      header: "Min Boundary After",
      size: 200,
      accessorFn: (row) =>
        row?.lowerThreshold && Object.keys(row.lowerThreshold).length === 0
          ? "NO CHANGE"
          : row?.lowerThreshold?.after ?? "N/A",
      Cell: ({ row }) => {
        return (
          <div
            style={{
              display: "flex", // Flexbox for centering content
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically
              width: "100%", // Ensure div takes the full width of the cell
              height: "100%", // Ensure div takes the full height of the cell
              textAlign: "center", // Center the text within the div
              color:
                row.original?.lowerThreshold &&
                Object.keys(row.original.lowerThreshold).length === 0
                  ? "grey"
                  : "orange",
              fontWeight:
                Object.keys(row.original.lowerThreshold).length === 0
                  ? "normal"
                  : "bold",
            }}
          >
            {row.original?.lowerThreshold &&
            Object.keys(row.original.lowerThreshold).length === 0
              ? "NO CHANGE"
              : row.original?.lowerThreshold?.after ?? "N/A"}
          </div>
        );
      },
    },
    {
      header: "Max Boundary Before",
      size: 200,
      accessorFn: (row) =>
        row?.upperThreshold && Object.keys(row.upperThreshold).length === 0
          ? "NO CHANGE"
          : row?.upperThreshold?.before ?? "N/A",
      Cell: ({ row }) => (
        <div
          style={{
            display: "flex", // Flexbox for centering content
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            width: "100%", // Ensure div takes the full width of the cell
            height: "100%", // Ensure div takes the full height of the cell
            color:
              row.original?.upperThreshold &&
              Object.keys(row.original.upperThreshold).length === 0
                ? "grey"
                : "black",
            textAlign: "center", // Center the text within the div
          }}
        >
          {row.original?.upperThreshold &&
          Object.keys(row.original.upperThreshold).length === 0
            ? "NO CHANGE"
            : row.original?.upperThreshold?.before ?? "N/A"}
        </div>
      ),
    },
    {
      header: "Max Boundary After",
      size: 200,
      accessorFn: (row) =>
        row?.upperThreshold && Object.keys(row.upperThreshold).length === 0
          ? "NO CHANGE"
          : row?.upperThreshold?.after ?? "N/A",
      Cell: ({ row }) => {
        return (
          <div
            style={{
              display: "flex", // Flexbox for centering content
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically
              width: "100%", // Ensure div takes the full width of the cell
              height: "100%", // Ensure div takes the full height of the cell
              textAlign: "center", // Center the text within the div
              color:
                row.original?.upperThreshold &&
                Object.keys(row.original.upperThreshold).length === 0
                  ? "grey"
                  : "orange",
              fontWeight:
                Object.keys(row.original.upperThreshold).length === 0
                  ? "normal"
                  : "bold",
            }}
          >
            {row.original?.upperThreshold &&
            Object.keys(row.original.upperThreshold).length === 0
              ? "NO CHANGE"
              : row.original?.upperThreshold?.after ?? "N/A"}
          </div>
        );
      },
    },
  ];

export const previewTableCols = {
  LastDigitNine: lastDigitNineChangesPreviewTableCols,
  PriceFallUnderMinMax: minMaxChangesPreviewTableCols,
  MaxIncreaseDecrease: maxIncDecChangesPreviewTableCols,
  AutoApproval: autoApprovalChangesPreviewTableCols,
  OpisAge: opisAgeChangesPreviewTableCols,
  CompetitorBoundary: compBoundaryPreviewTableCols,
};
