import { isEmpty } from "lodash";
import Products from "../constants/products.json";
import {
  AutoApproveFlag,
  AutoApproveFlagTable,
  CompetitorBoundary,
  CompetitorBoundaryTable,
  LastDigitNine,
  LastDigitNineTable,
  MaxIncDecrease,
  MaxIncDecTable,
  MinMaxPrice,
  MinMaxPriceTable,
  OpisAge,
  OpisAgeTable,
} from "../interfaces/IControlConfig";
import {
  AutoApproveFlagConfig,
  CompBoundaryConfig,
  LastDigitNineConfig,
  MaxIncDecControlConfig,
  MinMaxControlConfig,
  OpisAgeConfig,
} from "../interfaces/IControlConfigPatchRequest";
import { IControlConfigPatchResponse } from "../interfaces/IControlConfigPatchResponse";
import { IControlPreviewChanges } from "../interfaces/IControlPreviewChanges";
import { IStore } from "../interfaces/IStore";

export const mapMinMaxPriceToTableData = (
  minMaxPrice: MinMaxPrice,
  storeList: IStore[]
): MinMaxPriceTable => {
  return {
    id: storeList.find((store) => store.seiid === minMaxPrice.store_id).seiid,
    store: minMaxPrice.store_id,
    min_price_old: minMaxPrice.min_price,
    min_price_new: minMaxPrice.min_price,
    max_price_old: minMaxPrice.max_price,
    max_price_new: minMaxPrice.max_price,
    enabled_old: minMaxPrice.enabled ? "Enabled" : "Disabled",
    enabled_new: minMaxPrice.enabled ? "Enabled" : "Disabled",
    action_on_failure_old: minMaxPrice.action_on_failure,
    action_on_failure_new: minMaxPrice.action_on_failure,
  };
};

export const mapMaxIncDecToTableData = (
  maxIncDec: MaxIncDecrease,
  storeList: IStore[]
): MaxIncDecTable => {
  return {
    id: storeList.find((store) => store.seiid === maxIncDec.store_id).seiid,
    store: maxIncDec.store_id,
    max_increase_old: maxIncDec.max_increase,
    max_increase_new: maxIncDec.max_increase,
    max_decrease_old: maxIncDec.max_decrease,
    max_decrease_new: maxIncDec.max_decrease,
    enabled_old: maxIncDec.enabled ? "Enabled" : "Disabled",
    enabled_new: maxIncDec.enabled ? "Enabled" : "Disabled",
    action_on_failure_old: maxIncDec.action_on_failure,
    action_on_failure_new: maxIncDec.action_on_failure,
  };
};

export const mapAutoApproveToTableData = (
  autoApproveFlag: AutoApproveFlag,
  storeList: IStore[]
): AutoApproveFlagTable => {
  return {
    id: storeList.find((store) => store.seiid === autoApproveFlag.store_id)
      .seiid,
    store: autoApproveFlag.store_id,
    enabled_old: autoApproveFlag.flag ? "Enabled" : "Disabled",
    enabled_new: autoApproveFlag.flag ? "Enabled" : "Disabled",
  };
};

export const mapOpisAgeToTableData = (
  opisAge: OpisAge,
  storeList: IStore[]
): OpisAgeTable => {
  return {
    id: storeList.find((store) => store.seiid === opisAge.store_id).seiid,
    store: opisAge.store_id,
    opis_age_old: opisAge.opisAge,
    opis_age_new: opisAge.opisAge,
    enabled_old: opisAge.enabled ? "Enabled" : "Disabled",
    enabled_new: opisAge.enabled ? "Enabled" : "Disabled",
    action_on_failure_old: opisAge.action_on_failure,
    action_on_failure_new: opisAge.action_on_failure,
  };
};

export const mapCompBoundaryTableDat = (
  compBoundary: CompetitorBoundary
): CompetitorBoundaryTable => {
  return {
    id: compBoundary?.store_id + "_" + compBoundary?.opisId,
    store: compBoundary?.store_id,
    opisId: compBoundary?.opisId,
    brand: `${compBoundary?.brand ?? "-"}`,
    comp_price: `${
      compBoundary?.comp_price ? `$ ${compBoundary.comp_price}` : "-"
    }`,
    own_price: `${
      compBoundary?.proposed_price ? `$ ${compBoundary.proposed_price}` : "-"
    }`,
    description: `${compBoundary?.description ?? "-"}`,
    distance: compBoundary?.distance ? `${compBoundary.distance} mi` : "-",
    offset: compBoundary?.offset ? `$ ${compBoundary.offset}` : "N/A",
    lower_threshold_old: compBoundary?.lowerThreshold
      ? `${compBoundary?.lowerThreshold} ¢`
      : "N/A",
    lower_threshold_new: compBoundary?.lowerThreshold
      ? `${compBoundary?.lowerThreshold} ¢`
      : "N/A",
    upper_threshold_old: compBoundary?.upperThreshold
      ? `${compBoundary?.upperThreshold} ¢`
      : "N/A",
    upper_threshold_new: compBoundary?.upperThreshold
      ? `${compBoundary?.upperThreshold} ¢`
      : "N/A",
  };
};

export const mapLastDigitNineToTableData = (
  lastDigitNine: LastDigitNine,
  storeList: IStore[]
): LastDigitNineTable => {
  return {
    id: storeList.find((store) => store.seiid === lastDigitNine.store_id).seiid,
    store: lastDigitNine.store_id,
    enabled_old: lastDigitNine.enabled ? "Enabled" : "Disabled",
    enabled_new: lastDigitNine.enabled ? "Enabled" : "Disabled",
    action_on_failure_old: lastDigitNine.action_on_failure,
    action_on_failure_new: lastDigitNine.action_on_failure,
  };
};

export const mapMinMaxPriceChanges = ({
  action_on_failure_new,
  enabled_new,
  min_price_new,
  max_price_new,
}): MinMaxControlConfig => {
  const data = {
    validationType: "PriceFallUnderMinMax",
  };

  if (action_on_failure_new) {
    data["onFailAction"] = action_on_failure_new;
  }

  if (enabled_new) {
    const enabledObj = {
      flag: enabled_new.toLowerCase() === "enabled" ? true : false,
    };
    data["enabled"] = enabledObj;
  }

  const validationDataObj = {};
  if (min_price_new) {
    validationDataObj["minPrice"] = min_price_new;
  }

  if (max_price_new) {
    validationDataObj["maxPrice"] = max_price_new;
  }

  if (!isEmpty(validationDataObj)) {
    data["validationData"] = validationDataObj;
  }

  return data;
};

export const mapAutoApprovalChanges = ({
  enabled_new,
}): AutoApproveFlagConfig => {
  const data = {
    enabled: enabled_new.toLowerCase() === "enabled" ? true : false,
  };
  return data;
};

export const mapMaxIncDecChanges = ({
  action_on_failure_new,
  enabled_new,
  max_increase_new,
  max_decrease_new,
}): MaxIncDecControlConfig => {
  const data = {
    validationType: "MaxIncreaseDecrease",
  };

  if (action_on_failure_new) {
    data["onFailAction"] = action_on_failure_new;
  }

  if (enabled_new) {
    const enabledObj = {
      flag: enabled_new.toLowerCase() === "enabled" ? true : false,
    };
    data["enabled"] = enabledObj;
  }

  const validationDataObj = {};
  if (max_increase_new !== undefined && max_increase_new !== null) {
    validationDataObj["maxIncrease"] = max_increase_new;
  }

  if (max_decrease_new != undefined && max_decrease_new != null) {
    validationDataObj["maxDecrease"] = max_decrease_new;
  }

  if (!isEmpty(validationDataObj)) {
    data["validationData"] = validationDataObj;
  }

  return data;
};

export const mapOpisAgeChanges = ({
  action_on_failure_new,
  enabled_new,
  opis_age_new,
}): OpisAgeConfig => {
  const data = {
    validationType: "OpisAge",
  };

  if (action_on_failure_new) {
    data["onFailAction"] = action_on_failure_new;
  }

  if (enabled_new) {
    const enabledObj = {
      flag: enabled_new.toLowerCase() === "enabled" ? true : false,
    };
    data["enabled"] = enabledObj;
  }

  const validationDataObj = {};
  if (opis_age_new != undefined && opis_age_new != null) {
    validationDataObj["opisAge"] = opis_age_new;
  }

  if (!isEmpty(validationDataObj)) {
    data["validationData"] = validationDataObj;
  }

  return data;
};

export const mapLastDigitNineChanges = ({
  action_on_failure_new,
  enabled_new,
}): LastDigitNineConfig => {
  const data = {
    validationType: "LastDigitNine",
  };

  if (action_on_failure_new) {
    data["onFailAction"] = action_on_failure_new;
  }

  if (enabled_new) {
    const enabledObj = {
      flag: enabled_new.toLowerCase() === "enabled" ? true : false,
    };
    data["enabled"] = enabledObj;
  }

  return data;
};

export const mapCompBoundaryChanges = (fields, editedRow, opisId) => {
  const changedObj = {
    opisId,
  };

  fields.forEach((field: string) => {
    if (editedRow[field]) {
      const value = editedRow[field];
      if (field == "lower_threshold_new") {
        changedObj["lowerThreshold"] =
          value === "N/A" ? value : value.replace(/[^0-9-]/g, "");
      } else if (field === "upper_threshold_new") {
        changedObj["upperThreshold"] =
          value === "N/A" ? value : value.replace(/[^0-9-]/g, "");
      }
    }
  });
  return changedObj;
};

export function transformControlConfigChangesList(
  dataList: IControlConfigPatchResponse[]
): IControlPreviewChanges {
  return dataList.reduce(
    (result, { storeId, productId, controlConfigChanges }) => {
      const product = Products.find((prod) => prod.id === productId)?.name;

      for (const [controlName, changes] of Object.entries(
        controlConfigChanges
      )) {
        if (!result[controlName]) {
          result[controlName] = [];
        }

        if (controlName === "CompetitorBoundary") {
          // Group changes by store and opisId
          const opisChanges = changes.reduce(
            (acc, { property, before, after, opisId }) => {
              const key = `${storeId}_${opisId}`;
              if (!acc[key]) {
                acc[key] = {
                  storeId,
                  productId: product,
                  opisId,
                  lowerThreshold: {},
                  upperThreshold: {},
                };
              }
              acc[key][property] = { before, after };
              return acc;
            },
            {}
          );

          // Add all OPIS changes for this store
          result[controlName].push(...Object.values(opisChanges));
        } else {
          // Handle other controls as before
          const transformedChange = changes.reduce(
            (acc, { property, before, after }) => {
              if (property === "enabled" || property === "autoApprove") {
                acc[property] = {
                  before: before ? "Enabled" : "Disabled",
                  after: after ? "Enabled" : "Disabled",
                };
              } else {
                acc[property] = { before, after };
              }
              return acc;
            },
            {}
          );

          result[controlName].push({
            storeId,
            productId: product,
            ...transformedChange,
          });
        }
      }

      return result;
    },
    {}
  );
}

// export function transformControlConfigChangesList(
//   dataList: IControlConfigPatchResponse[]
// ): IControlPreviewChanges {
//   return dataList.reduce(
//     (result, { storeId, productId, controlConfigChanges }) => {
//       const product = Products.find((prod) => prod.id === productId).name;
//       // Process each control configuration change
//       for (const [controlName, changes] of Object.entries(
//         controlConfigChanges
//       )) {
//         if (!result[controlName]) {
//           result[controlName] = [];
//         }

//         // Transform each change into a nested structure with before and after values
//         const transformedChange = changes.reduce(
//           (acc, { property, before, after }) => {
//             if (property === "enabled" || property === "autoApprove") {
//               acc[property] = {
//                 before: before ? "Enabled" : "Disabled",
//                 after: after ? "Enabled" : "Disabled",
//               };
//             } else {
//               acc[property] = { before, after };
//             }
//             return acc;
//           },
//           {}
//         );

//         // Add the transformed change along with storeId and productId
//         result[controlName].push({
//           storeId,
//           productId: product,
//           ...transformedChange,
//         });
//       }

//       return result;
//     },
//     {}
//   );
// }
