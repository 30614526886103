import { SENumberInput } from "@7eleven/visops-react-ui-kit";
import { GridRenderEditCellParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import useControlStore from "../store/useStoreControl";

const MaxOpisAgeField: React.FC<GridRenderEditCellParams> = (params) => {
  const [value, setValue] = useState(params?.value);
  const { opisAgeGridErrors, setOpisAgeGridErrors } = useControlStore();
  const handleTooltipChange = (show: boolean, message: string) => {};

  useEffect(() => {
    params.api.setEditCellValue(
      {
        id: params.id,
        field: params.field,
        value: value,
      },
      true
    );
  }, [value]);

  const handleMaxOpisAgeChange = (value: string) => {
    setValue(value);

    if (value === "") {
      setOpisAgeGridErrors(
        params.id.toString(),
        params.field,
        "Value must be between 0 and 1000 hours",
        false
      );
      return;
    }

    const newValue = parseInt(value);
    if (!isNaN(newValue) && newValue <= 1000) {
      const isValid = newValue >= 0 && newValue <= 1000;
      if (!isValid) {
        setOpisAgeGridErrors(
          params.id.toString(),
          params.field,
          "Value must be between 0 and 1000 hours",
          false
        );
      } else {
        setOpisAgeGridErrors(params.id.toString(), params.field, "", false);
      }
    }
  };

  return (
    <SENumberInput
      label=""
      value={value ?? ""}
      size="small"
      variant="outlined"
      error={!!opisAgeGridErrors?.[params.id]?.[params.field]}
      min={0}
      max={1000}
      step={1}
      precision={0}
      tooltipMessage={opisAgeGridErrors?.[params.id]?.[params.field]}
      isTooltipControlled={false}
      handleChange={handleMaxOpisAgeChange}
      onTooltipChange={handleTooltipChange}
      endAdornmentText="hours"
      sx={{
        width: "100%",
        height: "100%",
        "& .MuiOutlinedInput-root": {
          height: "100%",
          padding: 0,
        },
        "& .MuiInputBase-input": {
          padding: "0 8px",
          textAlign: "left",
          boxSizing: "border-box",
        },
      }}
    />
  );
};

export default MaxOpisAgeField;
