import { Tooltip } from "@mui/material";
import { GridRenderEditCellParams } from "@mui/x-data-grid";
import useControlStore from "../store/useStoreControl";
import { useEffect } from "react";
import { validateCompetitorBoundaryRange } from "../utils/app-utils";

const CompStorePriceField: React.FC<GridRenderEditCellParams> = (params) => {
  const { compBoundaryGridWarnings, setCompBoundaryGridWarnings } =
    useControlStore();
  const getWarning = (field) => {
    const warning = compBoundaryGridWarnings?.[params.row.id]?.[field] || "";
    return warning;
  };
  useEffect(() => {
    const msg = validateCompetitorBoundaryRange(params?.row, null, null);
    setCompBoundaryGridWarnings(params?.row?.id, "comp_price", msg);
  }, []);
  return (
    <Tooltip title={getWarning(params.field)} arrow>
      <span
        style={{
          backgroundColor: compBoundaryGridWarnings?.[params.row.id]?.[
            params.field
          ]
            ? "rgba(255, 100, 100, 0.3)" // Highlight when there's a warning
            : "transparent", // Keep normal background when no warning
          // padding: "4px 8px",
          borderRadius: "4px",
          // display: "inline-block",
          // width: "100%",
          // textAlign: "center",
        }}
      >
        {params.value}
      </span>
    </Tooltip>
  );
};

export default CompStorePriceField;
