import { Box, Tooltip } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import products from "../constants/products.json";
import SEAutoComplete from "../core/SEAutoComplete";
import { ProductInfo } from "../interfaces/IStore";
import useControlStore from "../store/useStoreControl";

const SelectionPanel = ({
  selectedStoreIdList = [],
  isMaximized = false,
  selectedProduct,
  setSelectedProduct,
}) => {
  const [stores, setStores] = useState([]);
  // const { data, error, isLoading } = usePricegenStoreFromES();
  const productList: ProductInfo[] = useMemo(() => products, []);

  const {
    globalSelectedStores,
    setGlobalSelectedStores,
    setSelectedProduct: setGlobalSelectedProduct,
    editedMaxIncDecRowMeta,
    editedMinMaxRowMeta,
    editedAutoApprovalRowMeta,
    editedOpisAgeRowMeta,
    editedLastDigitNineRowMeta,
    globalMinMaxPriceValues,
    globalMaxIncDecValues,
    globalAutoApprovalValues,
    globalOpisAgeValues,
    globalLastDigitNineValues,
    inEditMode,
  } = useControlStore();

  const hasUnsavedChanges =
    Object.keys(editedMaxIncDecRowMeta).length > 0 ||
    Object.keys(editedMinMaxRowMeta).length > 0 ||
    Object.keys(editedAutoApprovalRowMeta).length > 0 ||
    Object.keys(editedOpisAgeRowMeta).length > 0 ||
    Object.keys(editedLastDigitNineRowMeta).length > 0 ||
    Object.keys(globalAutoApprovalValues).length > 0 ||
    Object.keys(globalMinMaxPriceValues).length > 0 ||
    Object.keys(globalMaxIncDecValues).length > 0 ||
    Object.keys(globalOpisAgeValues).length > 0 ||
    Object.keys(globalLastDigitNineValues).length > 0;
  const tableInEditMode = Object.keys(inEditMode)?.length > 0;

  const disabledTooltip =
    "Please save or discard your changes before selecting a different store or product";

  useEffect(() => {
    setStores(globalSelectedStores);
  }, [globalSelectedStores]);

  const handleStoreSelection = (event, value) => {
    if (!hasUnsavedChanges) {
      setGlobalSelectedStores(value);
    }
  };

  const handleProductSelection = (event, value) => {
    if (!hasUnsavedChanges) {
      setSelectedProduct(value);
      setGlobalSelectedProduct(value);
    }
  };

  return (
    <>
      {/* Stores AutoComplete */}
      <Box
        flex={1}
        minWidth='250px'
        display='flex'
        flexDirection='column'
        gap={1}
      >
        <Tooltip
          title={hasUnsavedChanges || tableInEditMode ? disabledTooltip : ""}
        >
          <div>
            {/* Wrapper div needed for Tooltip to work with disabled elements */}
            <SEAutoComplete
              label='Select Stores'
              limit={isMaximized ? 2 : 1}
              isMultiple={true}
              options={stores}
              disabled={hasUnsavedChanges || tableInEditMode}
              isRequired={true}
              onChange={handleStoreSelection}
              size='medium'
              value={globalSelectedStores}
              labelKey='displayname'
              valueKey='importcode'
            />
          </div>
        </Tooltip>
      </Box>

      {/* Products AutoComplete */}
      <Box
        flex={1}
        minWidth='250px'
        display='flex'
        flexDirection='column'
        gap={1}
      >
        <Tooltip
          title={hasUnsavedChanges || tableInEditMode ? disabledTooltip : ""}
        >
          <div>
            <SEAutoComplete
              label='Select Product'
              limit={isMaximized ? 3 : 1}
              isMultiple={false}
              options={productList}
              disabled={hasUnsavedChanges || tableInEditMode}
              isRequired={true}
              onChange={handleProductSelection}
              size='medium'
              value={selectedProduct}
              labelKey='name'
              valueKey='id'
            />
          </div>
        </Tooltip>
      </Box>
    </>
  );
};

export default SelectionPanel;
