import { Tooltip } from "@mui/material";
import CompBoundaryEditField from "../components/ComBoundaryEditField";
import MaxIncDecEditField from "../components/MaxIncDecEditField";
import MaxOpisAgeField from "../components/MaxOpisAgeField";
import PriceEditField from "../components/PriceEditField";
import { TabInfo } from "../interfaces/ITabs";
import CompStorePriceField from "../components/CompStorePriceField";
import useControlStore from "../store/useStoreControl";
import { validateCompetitorBoundaryRange } from "../utils/app-utils";

export const validationTabs: TabInfo[] = [
  {
    name: "Third Digit Nine",
    id: "lastDigitNine",
    isGlobalReq: true,
    hideIdCol: false,
    sortOrder: 1,
  },
  {
    name: "Min/Max Price",
    id: "minMaxPrice",
    isGlobalReq: true,
    hideIdCol: false,
    sortOrder: 2,
  },
  {
    name: "Max Inc/Dec",
    id: "maxIncDec",
    isGlobalReq: true,
    hideIdCol: false,
    sortOrder: 3,
  },
  {
    name: "Auto Approval",
    id: "autoApproval",
    isGlobalReq: true,
    hideIdCol: false,
    sortOrder: 4,
  },
  {
    name: "Opis Age",
    id: "opisAge",
    isGlobalReq: true,
    hideIdCol: false,
    sortOrder: 5,
  },
  {
    name: "Competitor Boundary",
    id: "compBoundary",
    isGlobalReq: false,
    hideIdCol: true,
    sortOrder: 6,
  },
].sort((a, b) => a.sortOrder - b.sortOrder);

const minMaxTabTableCols = [
  {
    field: "id",
    headerName: "SEI ID",
    flex: 1,
    minWidth: 50,
  },
  {
    field: "min_price_old",
    headerName: "Min Price OLD",
    type: "number",
    flex: 1,
    editable: false,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "min_price_new",
    headerName: "Min Price NEW",
    flex: 1,
    editable: true,
    minWidth: 90,
    align: "left",
    headerAlign: "left",
    renderEditCell: (params) => <PriceEditField {...params} />,
  },
  {
    field: "max_price_old",
    headerName: "Max Price OLD",
    type: "number",
    flex: 1,
    editable: false,
    minWidth: 90,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "max_price_new",
    headerName: "Max Price NEW",
    flex: 1,
    editable: true,
    minWidth: 90,
    align: "left",
    headerAlign: "left",
    renderEditCell: (params) => <PriceEditField {...params} />,
  },
  {
    field: "enabled_old",
    headerName: "Enabled OLD",
    flex: 1,
    editable: false,
    minWidth: 90,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "enabled_new",
    headerName: "Enabled NEW",
    type: "singleSelect",
    valueOptions: ["Enabled", "Disabled"],
    flex: 1,
    editable: true,
    minWidth: 90,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "action_on_failure_old",
    headerName: "Failure Action OLD",
    flex: 1,
    minWidth: 90,
    editable: false,
    align: "left",
    headerAlign: "left",
    valueFormatter: (params) => {
      return minMaxPriceActionOnFailureMappings?.[params] || params;
    },
  },
  {
    field: "action_on_failure_new",
    headerName: "Failure Action NEW",
    flex: 1,
    minWidth: 90,
    type: "singleSelect",
    align: "left",
    headerAlign: "left",
    valueOptions: [
      { value: "LimitPrice", label: "Limit Price" },
      { value: "RequireApproval", label: "Require Manual Approval" },
      { value: "BlockPriceGenCycle", label: "Block Price Export" },
    ],

    // valueOptions: ["MaxLimit", "RequireApproval", "BlockPriceGenCycle"],
    editable: true,
    getOptionValue: (option) => option.value,
    getOptionLabel: (option) => option.label,
    valueFormatter: (params) => {
      return minMaxPriceActionOnFailureMappings?.[params] || params;
    },
  },
];

const maxIncDecTabTableCols = [
  {
    field: "id",
    headerName: "SEI ID",
    flex: 1,
    minWidth: 50,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "max_decrease_old",
    headerName: "Max Decrease OLD",
    type: "number",
    flex: 1,
    editable: false,
    minWidth: 100,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "max_decrease_new",
    headerName: "Max Decrease NEW",
    flex: 1,
    editable: true,
    minWidth: 90,
    align: "left",
    headerAlign: "left",
    renderEditCell: (params) => <MaxIncDecEditField {...params} />,
  },
  {
    field: "max_increase_old",
    headerName: "Max Increase OLD",
    type: "number",
    editable: false,
    flex: 1,
    minWidth: 90,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "max_increase_new",
    headerName: "Max Increase NEW",
    editable: true,
    flex: 1,
    minWidth: 90,
    align: "left",
    headerAlign: "left",
    renderEditCell: (params) => <MaxIncDecEditField {...params} />,
  },
  {
    field: "enabled_old",
    headerName: "Enabled OLD",
    editable: false,
    flex: 1,
    minWidth: 90,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "enabled_new",
    headerName: "Enabled NEW",
    editable: true,
    type: "singleSelect",
    valueOptions: ["Enabled", "Disabled"],
    flex: 1,
    minWidth: 90,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "action_on_failure_old",
    headerName: "Failure Action OLD",
    flex: 1,
    minWidth: 90,
    editable: false,
    align: "left",
    headerAlign: "left",
    valueFormatter: (params) => {
      return maxIncDecActionOnFailureMappings?.[params] || params;
    },
  },
  {
    field: "action_on_failure_new",
    headerName: "Failure Action NEW",
    flex: 1,
    minWidth: 90,
    type: "singleSelect",
    align: "left",
    headerAlign: "left",
    valueOptions: [
      { value: "MaxLimit", label: "Limit Price" },
      { value: "RequireApproval", label: "Require Manual Approval" },
      { value: "BlockPriceGenCycle", label: "Block Price Export" },
    ],

    // valueOptions: ["MaxLimit", "RequireApproval", "BlockPriceGenCycle"],
    editable: true,
    getOptionValue: (option) => option.value,
    getOptionLabel: (option) => option.label,
    valueFormatter: (params) => {
      return maxIncDecActionOnFailureMappings?.[params] || params;
    },
  },
];

const autoApprovalTableCols = [
  {
    field: "id",
    headerName: "SEI ID",
    flex: 1,
    minWidth: 50,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "enabled_old",
    headerName: "Auto Approval Flag OLD",
    editable: false,
    flex: 1,
    minWidth: 90,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "enabled_new",
    headerName: "Auto Approval Flag NEW",
    editable: true,
    type: "singleSelect",
    valueOptions: ["Enabled", "Disabled"],
    flex: 1,
    minWidth: 90,
    align: "left",
    headerAlign: "left",
  },
];

const opisAgeTabTableCols = [
  {
    field: "id",
    headerName: "SEI ID",
    flex: 1,
    minWidth: 50,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "opis_age_old",
    headerName: "Max Age OLD (in hours)",
    type: "range",
    flex: 1,
    editable: false,
    minWidth: 90,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "opis_age_new",
    headerName: "Max Age NEW (in hours)",
    type: "range",
    flex: 1,
    editable: true,
    minWidth: 90,
    align: "left",
    headerAlign: "left",
    renderEditCell: (params) => <MaxOpisAgeField {...params} />,
  },
  {
    field: "enabled_old",
    headerName: "Enabled OLD",
    flex: 1,
    editable: false,
    minWidth: 90,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "enabled_new",
    headerName: "Enabled NEW",
    type: "singleSelect",
    valueOptions: ["Enabled", "Disabled"],
    flex: 1,
    editable: true,
    minWidth: 90,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "action_on_failure_old",
    headerName: "Failure Action OLD",
    flex: 1,
    minWidth: 90,
    editable: false,
    align: "left",
    headerAlign: "left",
    valueFormatter: (params) => {
      return opisAgeOnFailureMappings?.[params] || params;
    },
  },
  {
    field: "action_on_failure_new",
    headerName: "Failure Action NEW",
    flex: 1,
    minWidth: 90,
    type: "singleSelect",
    align: "left",
    headerAlign: "left",
    valueOptions: [
      { value: "RemoveOpisProductPrice", label: "Ignore Competitor" },
    ],

    editable: true,
    getOptionValue: (option) => option.value,
    getOptionLabel: (option) => option.label,
    valueFormatter: (params) => {
      return opisAgeOnFailureMappings?.[params] || params;
    },
  },
];
const compBoundaryTableCols = [
  {
    field: "id",
    headerName: "ID",
    flex: 1,
    minWidth: 50,
    align: "left",
    headerAlign: "left",
    hide: true,
  },
  {
    field: "store",
    headerName: "SEI ID",
    flex: 1,
    minWidth: 50,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "opisId",
    headerName: "OPIS ID",
    flex: 1,
    minWidth: 80,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
    minWidth: 100,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "brand",
    headerName: "Brand",
    flex: 1,
    minWidth: 80,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "distance",
    headerName: "Distance (in miles)",
    flex: 1,
    minWidth: 80,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "offset",
    headerName: "Offset (in dollars)",
    flex: 1,
    minWidth: 30,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "comp_price",
    headerName: "Comp Price (in dollars)",
    flex: 1,
    minWidth: 80,
    align: "left",
    headerAlign: "left",
    renderCell: (params) => {
      return <CompStorePriceField {...params} />;
    },
  },
  {
    field: "own_price",
    headerName: "Own Store Price (in dollars)",
    flex: 1,
    minWidth: 80,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "lower_threshold_old",
    headerName: "Min Boundary OLD (in cents)",
    type: "number",
    flex: 1,
    editable: false,
    minWidth: 100,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "lower_threshold_new",
    headerName: "Min Boundary NEW (in cents)",
    flex: 1,
    editable: true,
    minWidth: 90,
    align: "left",
    headerAlign: "left",
    renderEditCell: (params) => <CompBoundaryEditField {...params} />,
  },
  {
    field: "upper_threshold_old",
    headerName: "Max Boundary OLD (in cents)",
    type: "number",
    editable: false,
    flex: 1,
    minWidth: 90,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "upper_threshold_new",
    headerName: "Max Boundary NEW (in cents)",
    editable: true,
    flex: 1,
    minWidth: 90,
    align: "left",
    headerAlign: "left",
    renderEditCell: (params) => <CompBoundaryEditField {...params} />,
  },
];

const lastDigitNineTabTableCols = [
  {
    field: "id",
    headerName: "SEI ID",
    flex: 1,
    minWidth: 50,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "enabled_old",
    headerName: "Enabled OLD",
    editable: false,
    flex: 1,
    minWidth: 90,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "enabled_new",
    headerName: "Enabled NEW",
    editable: true,
    type: "singleSelect",
    valueOptions: ["Enabled", "Disabled"],
    flex: 1,
    minWidth: 90,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "action_on_failure_old",
    headerName: "Failure Action OLD",
    flex: 1,
    minWidth: 90,
    editable: false,
    align: "left",
    headerAlign: "left",
    valueFormatter: (params) => {
      return lastDigitNineFailureMappings?.[params] || params;
    },
  },
  {
    field: "action_on_failure_new",
    headerName: "Failure Action NEW",
    flex: 1,
    minWidth: 90,
    type: "singleSelect",
    align: "left",
    headerAlign: "left",
    valueOptions: [{ value: "EndWithNine", label: "Set Third Digit to 9" }],
    editable: true,
    getOptionValue: (option) => option.value,
    getOptionLabel: (option) => option.label,
    valueFormatter: (params) => {
      return lastDigitNineFailureMappings?.[params] || params;
    },
  },
];

export const controlTabTableCols = {
  lastDigitNine: lastDigitNineTabTableCols,
  minMaxPrice: minMaxTabTableCols,
  maxIncDec: maxIncDecTabTableCols,
  autoApproval: autoApprovalTableCols,
  opisAge: opisAgeTabTableCols,
  compBoundary: compBoundaryTableCols,
};

export const globalInputConfigs = {
  lastDigitNine: {
    enabled_new: {
      label: "Enable Flag",
      type: "singleSelect",
      options: [
        { value: "Enabled", label: "Enabled" },
        { value: "Disabled", label: "Disabled" },
      ],
      default_val: "Select Enabled Flag",
    },
    action_on_failure_new: {
      label: "Failure Actions",
      type: "singleSelect",
      options: [{ value: "EndWithNine", label: "Set Third Digit to 9" }],
      default_val: "Select Failure Action",
    },
  },
  minMaxPrice: {
    min_price_new: {
      label: "Min Price",
      type: "number",
      min: 0,
      step: 0.01,
      precision: 3,
    },
    max_price_new: {
      label: "Max Price",
      type: "number",
      min: 0,
      step: 0.01,
      precision: 3,
    },
    enabled_new: {
      label: "Enable Flag",
      type: "singleSelect",

      options: [
        { value: "Enabled", label: "Enabled" },
        { value: "Disabled", label: "Disabled" },
      ],
      default_val: "Select Enabled Flag",
    },
    action_on_failure_new: {
      label: "Failure Actions",
      type: "singleSelect",
      options: [
        { value: "LimitPrice", label: "Limit Price" },
        { value: "RequireApproval", label: "Require Manual Approval" },
        { value: "BlockPriceGenCycle", label: "Block Price Export" },
      ],
      default_val: "Select Failure Action",
    },
  },
  maxIncDec: {
    max_decrease_new: {
      label: "Max Decrease",
      type: "range",
      min: 0.0,
      max: 1.0,
      step: 0.01,
      precision: 2,
    },
    max_increase_new: {
      label: "Max Increase",
      type: "range",
      min: 0.0,
      max: 1.0,
      step: 0.01,
      precision: 2,
    },
    enabled_new: {
      label: "Enable Flag",
      type: "singleSelect",
      options: [
        { value: "Enabled", label: "Enabled" },
        { value: "Disabled", label: "Disabled" },
      ],
      default_val: "Select Enabled Flag",
    },
    action_on_failure_new: {
      label: "Failure Actions",
      type: "singleSelect",
      options: [
        { value: "MaxLimit", label: "Limit Price" },
        { value: "RequireApproval", label: "Require Manual Approval" },
        { value: "BlockPriceGenCycle", label: "Block Price Export" },
      ],
      default_val: "Select Failure Action",
    },
  },
  autoApproval: {
    enabled_new: {
      label: "Auto Approval Flag",
      type: "singleSelect",
      options: [
        { value: "Enabled", label: "Enabled" },
        { value: "Disabled", label: "Disabled" },
      ],
      default_val: "Select Auto Approval Flag",
    },
  },
  opisAge: {
    opis_age_new: {
      label: "Max Age",
      type: "range",
      min: 0,
      max: 1000,
      precision: 0,
      step: 1,
      endAdornmentText: "hours",
    },
    enabled_new: {
      label: "Enable Flag",
      type: "singleSelect",
      options: [
        { value: "Enabled", label: "Enabled" },
        { value: "Disabled", label: "Disabled" },
      ],
      default_val: "Select Enabled Flag",
    },
    action_on_failure_new: {
      label: "Failure Actions",
      type: "singleSelect",
      options: [
        { value: "RemoveOpisProductPrice", label: "Ignore Competitor" },
      ],
      default_val: "Select Failure Action",
    },
  },
};

export const storeTablecols = [
  { field: "seiid", headerName: "SEI ID", width: 50, flex: 1 },
  {
    field: "displayname",
    headerName: "Name",
    flex: 1,
    width: 100,
  },
  {
    field: "dma",
    headerName: "Dma Name",
    flex: 1,
    width: 150,
  },
  {
    field: "brandname",
    headerName: "Brand Name",
    flex: 1,
    width: 110,
  },
];

export const sheetHeaderMapping = {
  storeId: "Store",
  productId: "Product",
  opisId: "Opis ID",
  onFailAction: {
    before: "On Fail Action Before",
    after: "On Fail Action After",
  },
  enabled: {
    before: "Enabled Before",
    after: "Enabled After",
  },
  minPrice: {
    before: "Min Price Before",
    after: "Min Price After",
  },
  maxPrice: {
    before: "Max Price Before",
    after: "Max Price After",
  },
  maxIncrease: {
    before: "Max Increase Before",
    after: "Max Increase After",
  },
  maxDecrease: {
    before: "Max Decrease Before",
    after: "Max Decrease After",
  },
  autoApprove: {
    before: "Auto Approve Flag Before",
    after: "Auto Approve Flag After",
  },
  opisAge: {
    before: "Max Age Before",
    after: "Max Age After",
  },
  lowerThreshold: {
    before: "Min Boundary Before",
    after: "Min Boundary After",
  },
  upperThreshold: {
    before: "Max Boundary Before",
    after: "Max Boundary After",
  },
};

export const controlNameMapping = {
  LastDigitNine: { name: "Third Digit Nine", sortOrder: 1 },
  PriceFallUnderMinMax: { name: "Min/Max Price", sortOrder: 2 },
  MaxIncreaseDecrease: { name: "Max Inc/Dec", sortOrder: 3 },
  AutoApproval: { name: "Auto Approval Flag", sortOrder: 4 },
  OpisAge: { name: "Opis Age", sortOrder: 5 },
  CompetitorBoundary: { name: "Competitor Boundary", sortOrder: 5 },
};

const maxIncDecActionOnFailureMappings = {
  MaxLimit: "Limit Price",
  RequireApproval: "Require Manual Approval",
  BlockPriceGenCycle: "Block Price Export",
};

const minMaxPriceActionOnFailureMappings = {
  LimitPrice: "Limit Price",
  RequireApproval: "Require Manual Approval",
  BlockPriceGenCycle: "Block Price Export",
};

const opisAgeOnFailureMappings = {
  RemoveOpisProductPrice: "Ignore Competitor",
};

const lastDigitNineFailureMappings = {
  EndWithNine: "Set Third Digit to 9",
};
