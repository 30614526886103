import {
  GridRowId,
  GridRowModes,
  GridRowSelectionModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  useGridApiRef,
} from "@mui/x-data-grid";
import { AxiosError } from "axios";
import React, { ReactNode, useEffect, useState } from "react";
import { controlTabTableCols } from "../constants/config";
import SEBulkEditingDataGrid from "../core/SEBulkEditingDataGrid";
import SESkeletonLoader from "../core/SESkeletonLoader";
import {
  useGetMultiStoreSingleProductControl,
  useGetMultiStoreSingleProductControlV2,
} from "../hooks/controlConfigApiHooks";
import useControlStore from "../store/useStoreControl";
import {
  mapAutoApproveToTableData,
  mapCompBoundaryTableDat,
  mapLastDigitNineToTableData,
  mapMaxIncDecToTableData,
  mapMinMaxPriceToTableData,
  mapOpisAgeToTableData,
} from "../utils/mapper";
import ErrorDisplay from "./ErrorDisplay";
import { IStoreDetails } from "../interfaces/IControlConfigRequest";
import { validateCompetitorBoundaryRange } from "../utils/app-utils";

interface DataPanelProps {
  selectedStores: IStoreDetails[];
  selectedProduct: string;
  children: ReactNode;
  tabId: string;
  processRowUpdate: any;
  hideIdCol: boolean;
}

const DataPanel: React.FC<DataPanelProps> = ({
  children,
  selectedStores,
  selectedProduct,
  tabId,
  processRowUpdate,
  hideIdCol = false,
}) => {
  const apiRef = useGridApiRef();
  const {
    originalLastDigitNineData,
    originalMaxIncDec,
    originalMinMaxPrice,
    originalAutoApproveFlagData,
    originalOpisAgeData,
    originalCompBoundaryData,
    editedLastDigitNineData,
    editedMinMaxPrice,
    editedMaxIncDec,
    editedAutoApproveFlagData,
    editedOpisAgeData,
    editedCompBoundaryData,
    editedLastDigitNineRowMeta,
    editedMinMaxRowMeta,
    editedMaxIncDecRowMeta,
    editedAutoApprovalRowMeta,
    editedOpisAgeRowMeta,
    editedCompBoundaryRowMeta,
    minMaxGridErrors,
    maxIncDecGridErrors,
    opisAgeGridErrors,
    compBoundaryGridErrors,
    setLastDigitNineData,
    setMinMaxPriceData,
    setMaxIncData,
    setAutoApproveFlagData,
    setOpisAgeData,
    setCompBoundaryData,
    updateLastDigitNine,
    updateMinMaxPrice,
    updateMaxIncDec,
    updateAutoApproval,
    updateOpisAge,
    updateCompBoundary,
    globalSelectedStores,
    resetControlData,
    setInEditMode,
    setMinMaxGridErrors,
    setMaxIncDecGridErrors,
    setOpisAgeGridErrors,
    setCompBoundaryGridErrors,
    setCompBoundaryGridWarnings,
  } = useControlStore();

  const {
    data: fetchedData,
    error: fetchError,
    isLoading: fetchLoading,
    isFetching,
    refetch,
  } = useGetMultiStoreSingleProductControlV2(selectedStores, selectedProduct);

  // State for dynamic height
  const [gridHeight, setGridHeight] = useState(window.innerHeight * 0.5); // Initial height (50% of viewport)
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // State for error message

  // Handle product changes
  useEffect(() => {
    const handleProductChange = async () => {
      if (selectedProduct) {
        console.log("Product changed to:", selectedProduct);
        resetControlData();
        await refetch();
      }
    };

    handleProductChange();
  }, [selectedProduct]); // Only depend on selectedProduct

  // Update store data when fetch completes
  useEffect(() => {
    if (fetchedData && !isFetching) {
      console.log("Setting new data for product:", selectedProduct);
      setLastDigitNineData(
        fetchedData.lastDigitNine.map((data) =>
          mapLastDigitNineToTableData(data, globalSelectedStores)
        )
      );
      setMinMaxPriceData(
        fetchedData.minMaxPrice.map((data) =>
          mapMinMaxPriceToTableData(data, globalSelectedStores)
        )
      );
      setMaxIncData(
        fetchedData.maxIncDec.map((data) =>
          mapMaxIncDecToTableData(data, globalSelectedStores)
        )
      );
      setAutoApproveFlagData(
        fetchedData?.autoApproveFlag?.map((data) =>
          mapAutoApproveToTableData(data, globalSelectedStores)
        )
      );
      setOpisAgeData(
        fetchedData?.opisAge?.map((data) =>
          mapOpisAgeToTableData(data, globalSelectedStores)
        )
      );
      setCompBoundaryData(
        fetchedData?.competitorBoundary?.map((data) =>
          mapCompBoundaryTableDat(data)
        )
      );
    }
  }, [fetchedData, isFetching]); // Remove selectedProduct dependency

  // Show loading state while fetching
  const isLoadingData = fetchLoading || isFetching;

  // Handle fetch errors
  useEffect(() => {
    if (fetchError) {
      console.error(
        "Error fetching data for product:",
        selectedProduct,
        fetchError
      );
      if (fetchError instanceof AxiosError) {
        // @ts-ignore
        const message =
          fetchError.response?.data?.message ||
          fetchError.message ||
          "An error occurred";
        setErrorMessage(message); // Set the error message
      } else {
        setErrorMessage("An unexpected error occurred.");
      }
    }
  }, [fetchError, selectedProduct]);

  const [rowModesModel, setRowModesModel] = useState({});

  // Function to update grid height based on window size
  const updateGridHeight = () => {
    console.log("height -- ", window.innerHeight * 0.5);

    const newHeight = Math.min(window.innerHeight * 0.5, 470); // Minimum height of 300px
    setGridHeight(newHeight);
  };

  useEffect(() => {
    // Set initial height
    updateGridHeight();

    // Add resize listener
    window.addEventListener("resize", updateGridHeight);

    // Cleanup
    return () => window.removeEventListener("resize", updateGridHeight);
  }, []);

  const getControlData = (tabId: string) => {
    if (tabId === "lastDigitNine") {
      return editedLastDigitNineData;
    } else if (tabId === "minMaxPrice") {
      return editedMinMaxPrice;
    } else if (tabId === "maxIncDec") {
      return editedMaxIncDec;
    } else if (tabId === "autoApproval") {
      return editedAutoApproveFlagData;
    } else if (tabId === "opisAge") {
      return editedOpisAgeData;
    } else if (tabId === "compBoundary") {
      return editedCompBoundaryData;
    }
    return [];
  };

  const getSelectedRows = (tabId: string) => {
    if (tabId === "lastDigitNine") {
      return Object.keys(editedLastDigitNineData);
    } else if (tabId === "minMaxPrice") {
      return Object.keys(editedMinMaxRowMeta);
    } else if (tabId === "maxIncDec") {
      return Object.keys(editedMaxIncDecRowMeta);
    } else if (tabId === "autoApproval") {
      return Object.keys(editedAutoApprovalRowMeta);
    } else if (tabId === "opisAge") {
      return Object.keys(editedOpisAgeRowMeta);
    } else if (tabId === "compBoundary") {
      return Object.keys(editedCompBoundaryRowMeta);
    }
    return [];
  };

  const getControlDataOriginalRow = (tabId: string, id: string) => {
    if (tabId === "lastDigitNine") {
      return originalLastDigitNineData.find((val) => val.id === id);
    } else if (tabId === "minMaxPrice") {
      return originalMinMaxPrice.find((val) => val.id === id);
    } else if (tabId === "maxIncDec") {
      return originalMaxIncDec.find((val) => val.id === id);
    } else if (tabId === "autoApproval") {
      return originalAutoApproveFlagData.find((val) => val.id === id);
    } else if (tabId === "opisAge") {
      return originalOpisAgeData.find((val) => val.id === id);
    } else if (tabId === "compBoundary") {
      return originalCompBoundaryData.find((val) => val.id === id);
    }
  };

  const handleRowEditClick = React.useCallback(
    (id: GridRowId) => {
      console.log(tabId);
      setInEditMode(true, tabId, id.toString());
      // Get the row data
      const row = apiRef.current.getRow(id);
      setRowModesModel({
        ...rowModesModel,
        [tabId]: {
          ...rowModesModel[tabId],
          [id]: { mode: GridRowModes.Edit },
        },
      });
    },
    [tabId, rowModesModel]
  );

  const handleRowEditSave = React.useCallback(
    (id: GridRowId) => {
      setInEditMode(false, tabId, id.toString());
      // Stop editing the row and save changes
      setRowModesModel({
        ...rowModesModel,
        [tabId]: {
          ...rowModesModel[tabId],
          [id]: { mode: GridRowModes.View },
        },
      });
      const row = apiRef.current.getRow(id);
      processRowUpdate(row);
      // Optionally trigger a save action here if needed
    },
    [apiRef, processRowUpdate, tabId, rowModesModel]
  );
  const handleCancelClick = React.useCallback(
    (id: GridRowId) => {
      setInEditMode(false, tabId, id.toString());
      if (tabId === "minMaxPrice") {
        setMinMaxGridErrors(id.toString(), [], false);
      } else if (tabId === "maxIncDec") {
        setMaxIncDecGridErrors(id.toString(), undefined, undefined, true);
      } else if (tabId === "opisAge") {
        setOpisAgeGridErrors(id.toString(), undefined, undefined, true);
      } else if (tabId === "compBoundary") {
        setCompBoundaryGridErrors(id.toString(), [], false);
        const editedRow = editedCompBoundaryData.filter(
          (compRow) => compRow.id == id
        );
        const msg = validateCompetitorBoundaryRange(editedRow[0], null, null);
        setCompBoundaryGridWarnings(id.toString(), "comp_price", msg);
      }
      // Stop editing the row and save changes
      setRowModesModel({
        ...rowModesModel,
        [tabId]: {
          ...rowModesModel[tabId],
          [id]: { mode: GridRowModes.View, ignoreModifications: true },
        },
      });
      // Optionally trigger a save action here if needed
    },
    [tabId, rowModesModel]
  );

  const handleDiscardButtonClick = React.useCallback(
    (id) => {
      const originalRow = getControlDataOriginalRow(tabId, id);
      if (tabId === "lastDigitNine") {
        updateLastDigitNine(id, originalRow, []);
      } else if (tabId === "minMaxPrice") {
        updateMinMaxPrice(id, originalRow, []);
      } else if (tabId === "maxIncDec") {
        updateMaxIncDec(id, originalRow, []);
      } else if (tabId === "autoApproval") {
        updateAutoApproval(id, originalRow, []);
      } else if (tabId === "opisAge") {
        updateOpisAge(id, originalRow, []);
      } else if (tabId === "compBoundary") {
        updateCompBoundary(id, originalRow, []);
        const msg = validateCompetitorBoundaryRange(originalRow, null, null);
        setCompBoundaryGridWarnings(id.toString(), "comp_price", msg);
      }
    },
    [
      tabId,
      editedLastDigitNineData,
      editedMinMaxPrice,
      editedMaxIncDec,
      editedAutoApproveFlagData,
      editedOpisAgeData,
      editedCompBoundaryData,
    ]
  );

  const onRowSelectionToggle = React.useCallback(
    (newSelection: GridRowSelectionModel) => {
      //   const tabId = validationTabs[validationTabValue].id;
      //   const selectedIds = newSelection;
      //   const previouslySelectedIds = selectedRows[tabId] || [];
      //   const removedIds = previouslySelectedIds.filter(
      //     (id) => !selectedIds.includes(id)
      //   );
      //   removedIds.forEach((id) => handleDiscardButtonClick(id));
    },
    []
  );

  const getRowClassName = React.useCallback(
    ({ id }) => {
      if (tabId === "lastDigitNine") {
        return editedLastDigitNineRowMeta[id] ? "row--edited" : "";
      } else if (tabId === "minMaxPrice") {
        return editedMinMaxRowMeta[id] ? "row--edited" : "";
      } else if (tabId === "maxIncDec") {
        return editedMaxIncDecRowMeta[id] ? "row--edited" : "";
      } else if (tabId === "autoApproval") {
        return editedAutoApprovalRowMeta[id] ? "row--edited" : "";
      } else if (tabId === "opisAge") {
        return editedOpisAgeRowMeta[id] ? "row--edited" : "";
      } else if (tabId === "compBoundary") {
        return editedCompBoundaryRowMeta[id] ? "row--edited" : "";
      }
    },
    [
      tabId,
      editedLastDigitNineData,
      editedMinMaxRowMeta,
      editedMaxIncDecRowMeta,
      editedAutoApprovalRowMeta,
      editedOpisAgeRowMeta,
      editedCompBoundaryRowMeta,
    ]
  );

  const getUnsavedRowIds = (tabId: string) => {
    if (tabId === "lastDigitNine") {
      return Object.keys(editedLastDigitNineRowMeta);
    } else if (tabId === "minMaxPrice") {
      return Object.keys(editedMinMaxRowMeta);
    } else if (tabId === "maxIncDec") {
      return Object.keys(editedMaxIncDecRowMeta);
    } else if (tabId === "opisAge") {
      return Object.keys(editedOpisAgeRowMeta);
    } else if (tabId === "autoApproval") {
      return Object.keys(editedAutoApprovalRowMeta);
    } else if (tabId === "compBoundary") {
      return Object.keys(editedCompBoundaryRowMeta);
    }
    return [];
  };

  const getCellClassName = (params) => {
    const { id, field } = params;
    if (tabId === "lastDigitNine") {
      return editedLastDigitNineRowMeta?.[id] &&
        editedLastDigitNineRowMeta[id].includes(field)
        ? "cell--edited"
        : "";
    } else if (tabId === "minMaxPrice") {
      return editedMinMaxRowMeta?.[id] &&
        editedMinMaxRowMeta[id].includes(field)
        ? "cell--edited"
        : "";
    } else if (tabId === "maxIncDec") {
      return editedMaxIncDecRowMeta?.[id] &&
        editedMaxIncDecRowMeta[id].includes(field)
        ? "cell--edited"
        : "";
    } else if (tabId === "autoApproval") {
      return editedAutoApprovalRowMeta?.[id] &&
        editedAutoApprovalRowMeta[id]?.includes(field)
        ? "cell--edited"
        : "";
    } else if (tabId === "opisAge") {
      return editedOpisAgeRowMeta?.[id] &&
        editedOpisAgeRowMeta[id]?.includes(field)
        ? "cell--edited"
        : "";
    } else if (tabId === "compBoundary") {
      return editedCompBoundaryRowMeta?.[id] &&
        editedCompBoundaryRowMeta[id]?.includes(field)
        ? "cell--edited"
        : "";
    }
    return "";
  };
  const isSaveDisabled = (rowId) => {
    if (tabId === "minMaxPrice") {
      return !!minMaxGridErrors?.[rowId];
    } else if (tabId === "maxIncDec") {
      return !!maxIncDecGridErrors?.[rowId];
    } else if (tabId === "opisAge") {
      return !!opisAgeGridErrors?.[rowId];
    } else if (tabId === "compBoundary") {
      return !!compBoundaryGridErrors?.[rowId];
    }
  };

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        csvOptions={{
          fileName: "ControlData",
        }}
      />
    </GridToolbarContainer>
  );

  return (
    <>
      {children}
      <SESkeletonLoader
        isLoading={isLoadingData}
        variant="rectangular"
        width="100%"
        height={90}
      >
        {fetchError ? (
          <ErrorDisplay
            message={errorMessage || "An unexpected error occurred."}
            onRetry={refetch}
          />
        ) : (
          <div style={{ height: gridHeight }}>
            <SEBulkEditingDataGrid
              apiRef={apiRef}
              cols={controlTabTableCols[tabId]}
              rows={getControlData(tabId)}
              unsavedRows={getUnsavedRowIds(tabId)}
              isSaving={isLoadingData}
              processRowUpdate={processRowUpdate}
              getRowClassName={getRowClassName}
              getCellClassName={getCellClassName}
              handleEditClick={handleRowEditClick}
              handleSaveClick={handleRowEditSave}
              rowModesModel={rowModesModel?.[tabId] || {}}
              setRowModesModel={setRowModesModel}
              selectedRows={getSelectedRows(tabId)}
              handleDiscardButtonClick={handleDiscardButtonClick}
              onRowSelectionToggle={onRowSelectionToggle}
              toolbar={CustomToolbar}
              isSaveDisabled={isSaveDisabled}
              handleCancelClick={handleCancelClick}
              hideIdColumn={hideIdCol}
            />
          </div>
        )}
      </SESkeletonLoader>
    </>
  );
};

export default DataPanel;
