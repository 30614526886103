import { SENumberInput } from "@7eleven/visops-react-ui-kit";
import { GridRenderEditCellParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import useControlStore from "../store/useStoreControl";
import { ICompBoundaryTableValidationFields } from "../interfaces/IStore";
import { validateCompetitorBoundaryRange } from "../utils/app-utils";

interface ValidationError {
  field: keyof ICompBoundaryTableValidationFields;
  message: string;
}
const CompBoundaryEditField: React.FC<GridRenderEditCellParams> = (params) => {
  const [value, setValue] = useState(() => {
    // Extract only digits from initial value
    const numericValue = String(params?.value).replace(/[^\d.-]/g, "");
    return numericValue || "";
  });
  const handleTooltipChange = (show: boolean, message: string) => {};
  useEffect(() => {
    params.api.setEditCellValue(
      {
        id: params.id,
        field: params.field,
        value: value ? value + " ¢" : "N/A",
      },
      true
    );
  }, [value]);
  const {
    compBoundaryGridErrors,
    setCompBoundaryGridErrors,
    setCompBoundaryGridWarnings,
  } = useControlStore();

  const validateCompBoundary = (field: string, newValue: string, row: any) => {
    const isValid = newValue == "" || Number.isFinite(Number(newValue));
    let currentFieldErrors = [];

    // Get existing errors for other fields
    const existingErrors = compBoundaryGridErrors?.[row.id] || {};
    const otherFieldErrors = Object.entries(existingErrors)
      .filter(([errorField, errorMsg]) => {
        const isThresholdError =
          typeof errorMsg === "string" &&
          errorMsg.includes("Threshold values are invalid");
        // For invalid input case: keep only non-threshold errors from other fields
        // For valid input case: keep all errors from other fields
        if (!isValid || newValue === "") {
          return errorField !== field && !isThresholdError;
        }
        return errorField !== field;
      })
      .map(([errorField, errorMsg]) => ({
        field: errorField as keyof ICompBoundaryTableValidationFields,
        message: errorMsg as string,
      }))
      .filter((error): error is ValidationError => error.message !== undefined);
    // 1. Check if value is invalid
    if (!isValid) {
      currentFieldErrors.push({
        field,
        message: `${newValue} is invalid`,
      });

      setCompBoundaryGridErrors(
        row.id,
        [...currentFieldErrors, ...otherFieldErrors],
        true
      );
      return;
    }

    // 2. Value is valid - handle empty and non-empty cases
    if (newValue === "") {
      setCompBoundaryGridErrors(row.id, otherFieldErrors, true);
      return;
    }
    // 3. Value is valid and not empty - compare with other field
    const numericValue = parseInt(newValue);
    const errorMessage =
      "Threshold values are invalid. Min Boundary must be less than Max Boundary";

    if (field === "lower_threshold_new" && row.upper_threshold_new !== "-") {
      const upperValue = parseInt(row.upper_threshold_new);
      if (numericValue >= upperValue) {
        currentFieldErrors = [
          {
            field: "lower_threshold_new",
            message: errorMessage,
          },
          {
            field: "upper_threshold_new",
            message: errorMessage,
          },
        ];
      } else {
        currentFieldErrors = [];
      }
    } else if (
      field === "upper_threshold_new" &&
      row.lower_threshold_new !== "-"
    ) {
      const lowerValue = parseInt(row.lower_threshold_new);
      if (numericValue <= lowerValue) {
        currentFieldErrors = [
          {
            field: "lower_threshold_new",
            message: errorMessage,
          },
          {
            field: "upper_threshold_new",
            message: errorMessage,
          },
        ];
      } else {
        currentFieldErrors = [];
      }
    }
    setCompBoundaryGridErrors(row.id, [...currentFieldErrors], true);
  };
  const validateCompBoundaryRange = (params, field, value) => {
    const msg = validateCompetitorBoundaryRange(params?.row, field, value);
    setCompBoundaryGridWarnings(params?.row?.id, "comp_price", msg);
  };

  const handleCompBoundaryChange = (value: string) => {
    validateCompBoundaryRange(params, params.field, value);
    validateCompBoundary(params.field, value, params.row);
    setValue(value);
  };
  return (
    <>
      <SENumberInput
        label=""
        value={value ?? ""}
        size="small"
        variant="outlined"
        error={!!compBoundaryGridErrors?.[params.id]?.[params.field]}
        min={-200}
        max={200}
        step={1}
        handleChange={handleCompBoundaryChange}
        tooltipMessage={compBoundaryGridErrors?.[params.id]?.[params.field]}
        isTooltipControlled={false}
        onTooltipChange={handleTooltipChange}
        precision={undefined}
        sx={{
          width: "100%",
          height: "100%",
          "& .MuiOutlinedInput-root": {
            height: "100%",
            padding: 0,
          },
          "& .MuiInputBase-input": {
            padding: "0 8px",
            textAlign: "left",
            boxSizing: "border-box",
          },
          "& .MuiInputAdornment-root": {
            marginRight: "8px",
            "& p": {
              color: "text.primary",
              fontWeight: 600,
              fontSize: "1rem",
            },
          },
        }}
        endAdornmentText="¢"
      />
    </>
  );
};

export default CompBoundaryEditField;
