import { create } from "zustand";
import {
  AutoApproveFlagTable,
  CompetitorBoundaryTable,
  MaxIncDecTable,
  MinMaxPriceTable,
  OpisAgeTable,
  LastDigitNineTable,
} from "../interfaces/IControlConfig";
import {
  ICompBoundaryTableValidationFields,
  IMaxIncDecTableValidationFields,
  IMinMaxTabelValidationFields,
  IOpisAgeTableValidationFields,
  IStore,
  ProductInfo,
} from "../interfaces/IStore";
import { IControlPreviewChanges } from "../interfaces/IControlPreviewChanges";
import { validateCompetitorBoundaryRange } from "../utils/app-utils";

// ControlState interface
interface ControlState {
  globalSelectedStores: IStore[];
  selectedProduct: ProductInfo;
  originalMinMaxPrice: MinMaxPriceTable[]; // Original data
  editedMinMaxPrice: MinMaxPriceTable[]; // Edited data
  originalMaxIncDec: MaxIncDecTable[]; // Original data
  editedMaxIncDec: MaxIncDecTable[]; // Edited data
  originalAutoApproveFlagData: AutoApproveFlagTable[]; // Original data
  editedAutoApproveFlagData: AutoApproveFlagTable[]; // Edited data
  originalOpisAgeData: OpisAgeTable[]; // Original data
  editedOpisAgeData: OpisAgeTable[]; // Edited data
  originalCompBoundaryData: CompetitorBoundaryTable[]; // Original data
  editedCompBoundaryData: CompetitorBoundaryTable[]; // Edited data
  originalLastDigitNineData: LastDigitNineTable[]; // Original data
  editedLastDigitNineData: LastDigitNineTable[]; // Edited data
  editedMinMaxRowIds: string[];
  editedMinMaxRowMeta: {};
  editedMaxIncDecRowMeta: {};
  editedAutoApprovalRowMeta: {};
  editedOpisAgeRowIds: string[];
  editedOpisAgeRowMeta: {};
  editedCompBoundaryRowMeta: {};
  editedMaxIncRowIds: string[];
  editedLastDigitNineRowIds: string[];
  editedLastDigitNineRowMeta: {};
  globalMinMaxPriceValues: {};
  globalMaxIncDecValues: {};
  globalAutoApprovalValues: {};
  globalOpisAgeValues: {};
  globalLastDigitNineValues: {};
  previewScreenData: IControlPreviewChanges;
  error: string | null;
  isLoading: boolean;
  isLoadingStore: boolean;
  isSubmitting: boolean;
  openPreviewScreen: boolean;
  globalValidationErrors: {};
  compBoundaryGridWarnings: {
    [key: string]: {
      [field: string]: string;
    };
  };
  minMaxGridErrors: {
    [key: string]: IMinMaxTabelValidationFields;
  };
  maxIncDecGridErrors: {
    [key: string]: IMaxIncDecTableValidationFields;
  };
  opisAgeGridErrors: {
    [key: string]: IOpisAgeTableValidationFields;
  };
  compBoundaryGridErrors: {
    [key: string]: ICompBoundaryTableValidationFields;
  };
  inEditMode: {
    [key: string]: {
      [key: string]: boolean;
    };
  };
  setSelectedProduct: (product: ProductInfo) => void;
  setPreviewScreenData: (previewScreenData: IControlPreviewChanges) => void;
  setGlobalSelectedStores: (stores: IStore[]) => void;
  setMinMaxPriceData: (data: MinMaxPriceTable[]) => void;
  setMinMaxPriceEditedRowId: (idList: string[]) => void;
  setMaxIncData: (data: MaxIncDecTable[]) => void;
  setAutoApproveFlagData: (data: AutoApproveFlagTable[]) => void;
  setOpisAgeData: (data: OpisAgeTable[]) => void;
  setCompBoundaryData: (data: CompetitorBoundaryTable[]) => void;
  setOpisAgeEditedRowId: (idList: string[]) => void;
  setLastDigitNineData: (data: LastDigitNineTable[]) => void;
  setLastDigitNineEditedRowId: (idList: string[]) => void;
  setMaxIncDecEditedRowId: (idList: string[]) => void;
  setGlobalMinMaxPriceFieldValues: (field: string, value: any) => void;
  setGlobalMaxIncDecFieldValues: (field: string, value: any) => void;
  setGlobalAutoApprovalFieldValues: (field: string, value: any) => void;
  setGlobalOpisAgeFieldValues: (field: string, value: any) => void;
  setGlobalLastDigitNineFieldValues: (field: string, value: any) => void;
  setCompBoundaryGridWarnings: (
    id: string,
    field: string,
    message: string
  ) => void;
  setMinMaxGridErrors: (
    id: string,
    errors: { field: string; message: string }[],
    isError: boolean
  ) => void;
  setMaxIncDecGridErrors: (
    id: string,
    field: string,
    error: string,
    resetErrors: boolean
    // errors: { field: string; message: string }[],
    // isError: boolean
  ) => void;
  setOpisAgeGridErrors: (
    id: string,
    field: string,
    error: string,
    resetErrors: boolean
  ) => void;
  setCompBoundaryGridErrors: (
    id: string,
    errors: { field: string; message: string }[],
    isError: boolean
  ) => void;
  // setCompBoundaryGridErrors: (
  //   id: string,
  //   field: string,
  //   error: string,
  //   resetErrors: boolean
  // ) => void;
  updateMinMaxPrice: (
    id: string,
    updatedRow: any,
    changedProperties: string[]
  ) => void;
  updateMaxIncDec: (
    id: string,
    updatedRow: any,
    changedProperties: string[]
  ) => void;
  updateAutoApproval: (
    id: string,
    updatedRow: any,
    changedProperties: string[]
  ) => void;
  updateOpisAge: (
    id: string,
    updatedRow: any,
    changedProperties: string[]
  ) => void;
  updateCompBoundary: (
    id: string,
    updatedRow: any,
    changedProperties: string[]
  ) => void;
  updateLastDigitNine: (
    id: string,
    updatedRow: any,
    changedProperties: string[]
  ) => void;
  revertAllGlobalMinMaxPriceFieldValues: () => void;
  revertAllGlobalMaxIncDecFieldValues: () => void;
  revertAllGlobalAutoApproveFieldValues: () => void;
  revertAllGlobalOpisAgeFieldValues: () => void;
  revertAllGlobalLastDigitNineFieldValues: () => void;
  revertMinMaxPriceChanges: () => void;
  revertMaxIncDecChanges: () => void;
  revertAutoApprovalChanges: () => void;
  revertOpisAgeChanges: () => void;
  revertLastDigitNineChanges: () => void;
  revertAllChanges: () => void;
  setError: (error: string | null) => void;
  setLoading: (isLoading: boolean) => void;
  setIsLoadingStores: (isLoading: boolean) => void;
  setIsSubmitting: (flag: boolean) => void;
  setOpenPreviewScreen: (falg: boolean) => void;
  setInEditMode: (flag: boolean, tabId: string, rowId: string) => void;
  resetStore: () => void;
  resetControlData: () => void;
}

// Create Zustand store
const useControlStore = create<ControlState>((set) => ({
  originalMinMaxPrice: [],
  editedMinMaxPrice: [],
  originalMaxIncDec: [],
  originalAutoApproveFlagData: [],
  originalOpisAgeData: [],
  originalCompBoundaryData: [],
  originalLastDigitNineData: [],
  editedMinMaxRowIds: [],
  editedMinMaxRowMeta: {},
  editedMaxIncDecRowMeta: {},
  editedAutoApprovalRowMeta: {},
  editedOpisAgeRowMeta: {},
  editedCompBoundaryRowMeta: {},
  editedLastDigitNineRowMeta: {},
  editedMaxIncDec: [],
  editedAutoApproveFlagData: [],
  editedOpisAgeData: [],
  editedCompBoundaryData: [],
  editedOpisAgeRowIds: [],
  editedLastDigitNineData: [],
  editedLastDigitNineRowIds: [],
  editedMaxIncRowIds: [],
  globalMinMaxPriceValues: {},
  globalMaxIncDecValues: {},
  globalAutoApprovalValues: {},
  globalOpisAgeValues: {},
  globalLastDigitNineValues: {},
  error: null,
  isLoading: false,
  isLoadingStore: true,
  isSubmitting: false,
  openPreviewScreen: false,
  globalSelectedStores: [],

  selectedProduct: {
    id: undefined,
    name: undefined,
  },
  previewScreenData: {},
  globalValidationErrors: {},
  minMaxGridErrors: {},
  compBoundaryGridWarnings: {},
  maxIncDecGridErrors: {},
  opisAgeGridErrors: {},
  compBoundaryGridErrors: {},
  inEditMode: {},
  setPreviewScreenData: (data: IControlPreviewChanges) =>
    set({ previewScreenData: data }),
  setSelectedProduct: (data) => set({ selectedProduct: data }),
  setGlobalSelectedStores: (data) => set({ globalSelectedStores: data }),
  setIsLoadingStores: (flag: boolean) => set({ isLoadingStore: flag }),
  setMinMaxPriceData: (data) =>
    set({ originalMinMaxPrice: data, editedMinMaxPrice: data }),
  setMinMaxPriceEditedRowId: (idList) => set({ editedMinMaxRowIds: idList }),
  setMaxIncData: (data) =>
    set({ originalMaxIncDec: data, editedMaxIncDec: data }),
  setAutoApproveFlagData: (data) =>
    set({ originalAutoApproveFlagData: data, editedAutoApproveFlagData: data }),
  setOpisAgeData: (data) =>
    set({ originalOpisAgeData: data, editedOpisAgeData: data }),
  setCompBoundaryData: (data) =>
    set({ originalCompBoundaryData: data, editedCompBoundaryData: data }),
  setOpisAgeEditedRowId: (idList) => set({ editedOpisAgeRowIds: idList }),
  setLastDigitNineData: (data) =>
    set({ originalLastDigitNineData: data, editedLastDigitNineData: data }),
  setLastDigitNineEditedRowId: (idList) =>
    set({ editedLastDigitNineRowIds: idList }),
  setMaxIncDecEditedRowId: (idList) => set({ editedMaxIncRowIds: idList }),
  setGlobalMinMaxPriceFieldValues: (field: string, value: any) =>
    set((state) => {
      const current = state.globalMinMaxPriceValues;
      const currentGlobalError = state.globalValidationErrors;
      if (value === undefined || value === null || value === "") {
        delete current[field];
      } else {
        current[field] = value;
      }
      // Perform validation checks
      const minPrice = Number(current["min_price_new"]);
      const maxPrice = Number(current["max_price_new"]);

      // Clear errors initially for both fields to ensure updated checks
      delete currentGlobalError["max_price_new"];
      delete currentGlobalError["min_price_new"];

      if (minPrice !== undefined && maxPrice !== undefined) {
        // Validate that max price is not less than min price
        if (maxPrice < minPrice) {
          if (field === "max_price_new") {
            currentGlobalError["max_price_new"] =
              "Max Price cannot be less than Min Price.";
          } else if (field === "min_price_new") {
            currentGlobalError["min_price_new"] =
              "Min Price cannot be greater than Max Price.";
          }
        } else if (maxPrice === minPrice) {
          if (field === "max_price_new") {
            currentGlobalError["max_price_new"] =
              "Max Price cannot be equal to Min Price.";
          } else if (field === "min_price_new") {
            currentGlobalError["min_price_new"] =
              "Min Price cannot be equal to Max Price.";
          }
        }
      }
      return {
        globalMinMaxPriceValues: current,
        globalValidationErrors: currentGlobalError,
      };
    }),
  setGlobalMaxIncDecFieldValues: (field: string, value: any) =>
    set((state) => {
      const current = state.globalMaxIncDecValues;
      if (value === undefined || value === null || value === "") {
        delete current[field];
      } else {
        current[field] = value;
      }
      return {
        globalMaxIncDecValues: current,
      };
    }),
  setGlobalAutoApprovalFieldValues: (field: string, value: any) =>
    set((state) => {
      const current = state.globalAutoApprovalValues;
      if (value === undefined || value === null || value === "") {
        delete current[field];
      } else {
        current[field] = value;
      }
      return {
        globalAutoApprovalValues: current,
      };
    }),
  setGlobalOpisAgeFieldValues: (field: string, value: any) =>
    set((state) => {
      const current = state.globalOpisAgeValues;
      if (value === undefined || value === null || value === "") {
        delete current[field];
      } else {
        current[field] = value;
      }
      return {
        globalOpisAgeValues: current,
      };
    }),
  setGlobalLastDigitNineFieldValues: (field: string, value: any) =>
    set((state) => {
      const current = state.globalLastDigitNineValues;
      if (value === undefined || value === null || value === "") {
        delete current[field];
      } else {
        current[field] = value;
      }
      return {
        globalLastDigitNineValues: current,
      };
    }),
  updateMinMaxPrice: (
    id: string,
    updatedRow: any,
    changedProperties: string[]
  ) =>
    set((state) => {
      const updatedData = state.editedMinMaxPrice.map((row) =>
        row.id === id ? { ...row, ...updatedRow } : row
      );

      let currentEditedMeta = state.editedMinMaxRowMeta;
      if (changedProperties.length === 0) {
        delete currentEditedMeta[id];
      } else {
        if (!currentEditedMeta[id]) {
          currentEditedMeta = {
            ...currentEditedMeta,
            [id]: changedProperties,
          };
        } else {
          currentEditedMeta[id] = changedProperties;
        }
      }
      return {
        editedMinMaxPrice: updatedData,
        editedMinMaxRowMeta: currentEditedMeta,
      };
    }),

  updateMaxIncDec: (id: string, updatedRow: any, changedProperties: string[]) =>
    set((state) => {
      const updatedData = state.editedMaxIncDec.map((row) =>
        row.id === id ? { ...row, ...updatedRow } : row
      );
      let currentEditedMeta = state.editedMaxIncDecRowMeta;
      if (changedProperties.length === 0) {
        delete currentEditedMeta[id];
      } else {
        if (!currentEditedMeta[id]) {
          currentEditedMeta = {
            ...currentEditedMeta,
            [id]: changedProperties,
          };
        } else {
          currentEditedMeta[id] = changedProperties;
        }
      }
      return {
        editedMaxIncDec: updatedData,
        editedMaxIncDecRowMeta: currentEditedMeta,
      };
    }),
  updateAutoApproval: (
    id: string,
    updatedRow: any,
    changedProperties: string[]
  ) =>
    set((state) => {
      const updatedData = state.editedAutoApproveFlagData.map((row) =>
        row.id === id ? { ...row, ...updatedRow } : row
      );

      let currentEditedMeta = state.editedAutoApprovalRowMeta;
      if (changedProperties.length === 0) {
        delete currentEditedMeta[id];
      } else {
        if (!currentEditedMeta[id]) {
          currentEditedMeta = {
            ...currentEditedMeta,
            [id]: changedProperties,
          };
        } else {
          currentEditedMeta[id] = changedProperties;
        }
      }
      return {
        editedAutoApproveFlagData: updatedData,
        editedAutoApprovalRowMeta: currentEditedMeta,
      };
    }),
  updateOpisAge: (id: string, updatedRow: any, changedProperties: string[]) =>
    set((state) => {
      const updatedData = state.editedOpisAgeData.map((row) =>
        row.id === id ? { ...row, ...updatedRow } : row
      );

      let currentEditedMeta = state.editedOpisAgeRowMeta;
      if (changedProperties.length === 0) {
        delete currentEditedMeta[id];
      } else {
        if (!currentEditedMeta[id]) {
          currentEditedMeta = {
            ...currentEditedMeta,
            [id]: changedProperties,
          };
        } else {
          currentEditedMeta[id] = changedProperties;
        }
      }
      return {
        editedOpisAgeData: updatedData,
        editedOpisAgeRowMeta: currentEditedMeta,
      };
    }),
  updateCompBoundary: (
    id: string,
    updatedRow: any,
    changedProperties: string[]
  ) =>
    set((state) => {
      const updatedData = state.editedCompBoundaryData.map((row) =>
        row.id === id ? { ...row, ...updatedRow } : row
      );

      let currentEditedMeta = state.editedCompBoundaryRowMeta;
      if (changedProperties.length === 0) {
        delete currentEditedMeta[id];
      } else {
        if (!currentEditedMeta[id]) {
          currentEditedMeta = {
            ...currentEditedMeta,
            [id]: changedProperties,
          };
        } else {
          currentEditedMeta[id] = changedProperties;
        }
      }
      return {
        editedCompBoundaryData: updatedData,
        editedCompBoundaryRowMeta: currentEditedMeta,
      };
    }),
  updateLastDigitNine: (
    id: string,
    updatedRow: any,
    changedProperties: string[]
  ) =>
    set((state) => {
      const updatedData = state.editedLastDigitNineData.map((row) =>
        row.id === id ? { ...row, ...updatedRow } : row
      );

      let currentEditedMeta = state.editedLastDigitNineRowMeta;
      if (changedProperties.length === 0) {
        delete currentEditedMeta[id];
      } else {
        if (!currentEditedMeta[id]) {
          currentEditedMeta = {
            ...currentEditedMeta,
            [id]: changedProperties,
          };
        } else {
          currentEditedMeta[id] = changedProperties;
        }
      }
      return {
        editedLastDigitNineData: updatedData,
        editedLastDigitNineRowMeta: currentEditedMeta,
      };
    }),
  revertMinMaxPriceChanges: () =>
    set((state) => ({ editedMinMaxPrice: state.originalMinMaxPrice })),
  revertAllGlobalMinMaxPriceFieldValues: () =>
    set((state) => ({
      globalMinMaxPriceValues: {},
      globalValidationErrors: {},
    })),
  revertAllGlobalMaxIncDecFieldValues: () =>
    set((state) => ({ globalMaxIncDecValues: {} })),
  revertAllGlobalAutoApproveFieldValues: () =>
    set((state) => ({ globalAutoApprovalValues: {} })),
  revertMaxIncDecChanges: () =>
    set((state) => ({ editedMaxIncDec: state.originalMaxIncDec })),
  revertAllGlobalOpisAgeFieldValues: () =>
    set((state) => ({ globalOpisAgeValues: {} })),
  revertAllGlobalLastDigitNineFieldValues: () =>
    set((state) => ({ globalLastDigitNineValues: {} })),
  revertAllChanges: () =>
    set((state) => {
      const updatedWarnings = { ...state.compBoundaryGridWarnings };

      Object.keys(state.editedCompBoundaryRowMeta).forEach((rowId) => {
        const originalRow = state.originalCompBoundaryData.find(
          (row) => row.id === rowId
        );
        if (originalRow) {
          const msg = validateCompetitorBoundaryRange(originalRow, null, null);
          if (msg) {
            // Initialize if rowId doesn't exist
            if (!updatedWarnings[rowId]) {
              updatedWarnings[rowId] = {};
            }

            // Add/overwrite field errors
            updatedWarnings[rowId] = { comp_price: msg };
          } else {
            // No errors, remove existing warnings if present
            if (updatedWarnings[rowId]) {
              delete updatedWarnings[rowId];
            }
          }
        }
      });

      return {
        globalMinMaxPriceValues: {},
        globalMaxIncDecValues: {},
        globalAutoApprovalValues: {},
        globalOpisAgeValues: {},
        globalLastDigitNineValues: {},
        editedMinMaxPrice: state.originalMinMaxPrice,
        editedMaxIncDec: state.originalMaxIncDec,
        editedAutoApproveFlagData: state.originalAutoApproveFlagData,
        editedOpisAgeData: state.originalOpisAgeData,
        editedLastDigitNineData: state.originalLastDigitNineData,
        editedCompBoundaryData: state.originalCompBoundaryData,
        editedAutoApprovalRowMeta: {},
        editedOpisAgeRowMeta: {},
        editedLastDigitNineRowMeta: {},
        editedMinMaxRowMeta: {},
        editedMaxIncDecRowMeta: {},
        editedCompBoundaryRowMeta: {},
        globalValidationErrors: {},
        compBoundaryGridWarnings: updatedWarnings,
      };
    }),
  revertAutoApprovalChanges: () =>
    set((state) => ({
      editedAutoApproveFlagData: state.originalAutoApproveFlagData,
    })),
  revertOpisAgeChanges: () =>
    set((state) => ({
      editedOpisAgeData: state.originalOpisAgeData,
    })),
  revertLastDigitNineChanges: () =>
    set((state) => ({
      editedLastDigitNineData: state.originalLastDigitNineData,
    })),
  setError: (error) => set({ error }),

  setLoading: (isLoading) => set({ isLoading }),
  setIsSubmitting: (flag) => set({ isSubmitting: flag }),
  setInEditMode: (flag, tabId, rowId) =>
    set((state) => {
      if (!flag) {
        const current = state.inEditMode[tabId];
        delete current[rowId];
        if (Object.keys(current)?.length > 0) {
          return { inEditMode: { [tabId]: { ...current } } };
        } else {
          const allTabState = state.inEditMode;
          delete allTabState[tabId];
          return { inEditMode: { ...allTabState } };
        }
      } else {
        return {
          inEditMode: {
            [tabId]: { ...state.inEditMode[tabId], ...{ [rowId]: flag } },
          },
        };
      }
    }),
  setMinMaxGridErrors: (
    id: string,
    errors: { field: string; message: string }[],
    isError: boolean
  ) =>
    set((state) => {
      if (isError) {
        let fieldError: IMinMaxTabelValidationFields = {
          min_price_new: undefined,
          max_price_new: undefined,
        };
        errors.forEach((error) => (fieldError[error.field] = error.message));
        return {
          minMaxGridErrors: {
            ...state.minMaxGridErrors,
            [id]: fieldError,
          },
        };
      }
      const currentMinMaxGridErrors = { ...state.minMaxGridErrors };
      delete currentMinMaxGridErrors[id];
      return { minMaxGridErrors: currentMinMaxGridErrors };
    }),
  setCompBoundaryGridWarnings: (id: string, field: string, message: string) =>
    set((state) => {
      const updatedRowWarnings = {
        ...state.compBoundaryGridWarnings[id],
        [field]: message,
      };

      // Remove the field if the message is empty
      if (!message) delete updatedRowWarnings[field];

      // Remove the row if no warnings remain
      const newWarnings =
        Object.keys(updatedRowWarnings).length === 0
          ? { ...state.compBoundaryGridWarnings }
          : { ...state.compBoundaryGridWarnings, [id]: updatedRowWarnings };

      if (!message) delete newWarnings[id];
      return {
        compBoundaryGridWarnings: newWarnings,
      };
    }),
  setMaxIncDecGridErrors: (
    id: string,
    field: string,
    error: string,
    resetErrors: boolean
    // errors: { field: string; message: string }[],
    // isError: boolean
  ) =>
    set((state) => {
      const currentMaxIncDecGridErrors = { ...state.maxIncDecGridErrors };
      if (resetErrors) {
        delete currentMaxIncDecGridErrors?.[id];
        return { maxIncDecGridErrors: currentMaxIncDecGridErrors };
      }
      let fieldError: IMaxIncDecTableValidationFields = {
        max_decrease_new: undefined,
        max_increase_new: undefined,
      };
      if (error) {
        fieldError[field] = error;
        return {
          maxIncDecGridErrors: {
            ...currentMaxIncDecGridErrors,
            [id]: fieldError,
          },
        };
      } else {
        if (currentMaxIncDecGridErrors?.[id]?.[field]) {
          delete currentMaxIncDecGridErrors[id][field];
        }
        return { maxIncDecGridErrors: currentMaxIncDecGridErrors };
      }
    }),
  setOpisAgeGridErrors: (
    id: string,
    field: string,
    error: string,
    resetErrors: boolean
  ) =>
    set((state) => {
      const currentOpisAgeGridErrors = { ...state.opisAgeGridErrors };
      if (resetErrors) {
        delete currentOpisAgeGridErrors[id];
        return { opisAgeGridErrors: currentOpisAgeGridErrors };
      }
      let fieldError: IOpisAgeTableValidationFields = {
        opis_age_new: undefined,
      };
      if (error) {
        fieldError[field] = error;
        currentOpisAgeGridErrors[id] = fieldError;
      } else {
        delete currentOpisAgeGridErrors[id];
      }
      return {
        opisAgeGridErrors: currentOpisAgeGridErrors,
      };
    }),
  setCompBoundaryGridErrors: (
    id: string,
    errors: { field: string; message: string }[],
    isError: boolean
  ) =>
    set((state) => {
      if (errors?.length == 0) {
        const currentCompBoundaGridErrors = { ...state.compBoundaryGridErrors };
        delete currentCompBoundaGridErrors[id];
        return { compBoundaryGridErrors: currentCompBoundaGridErrors };
      }
      if (isError) {
        let fieldError: ICompBoundaryTableValidationFields = {
          lower_threshold_new: undefined,
          upper_threshold_new: undefined,
        };
        errors.forEach((error) => (fieldError[error.field] = error.message));
        return {
          compBoundaryGridErrors: {
            ...state.compBoundaryGridErrors,
            [id]: fieldError,
          },
        };
      }
      const currentCompBoundaGridErrors = { ...state.compBoundaryGridErrors };
      delete currentCompBoundaGridErrors[id];
      return { compBoundaryGridErrors: currentCompBoundaGridErrors };
    }),
  setOpenPreviewScreen: (flag) =>
    set({
      originalMinMaxPrice: [],
      editedMinMaxPrice: [],
      originalMaxIncDec: [],
      originalAutoApproveFlagData: [],
      originalOpisAgeData: [],
      originalLastDigitNineData: [],
      originalCompBoundaryData: [],
      editedMinMaxRowIds: [],
      editedMinMaxRowMeta: {},
      editedMaxIncDecRowMeta: {},
      editedAutoApprovalRowMeta: {},
      editedCompBoundaryRowMeta: {},
      compBoundaryGridErrors: {},
      compBoundaryGridWarnings: {},
      editedOpisAgeRowIds: [],
      editedOpisAgeData: [],
      editedOpisAgeRowMeta: {},
      editedLastDigitNineRowIds: [],
      editedLastDigitNineData: [],
      editedLastDigitNineRowMeta: {},
      maxIncDecGridErrors: {},
      editedAutoApproveFlagData: [],
      editedMaxIncDec: [],
      editedMaxIncRowIds: [],
      globalMinMaxPriceValues: {},
      globalMaxIncDecValues: {},
      globalAutoApprovalValues: {},
      globalOpisAgeValues: {},
      globalLastDigitNineValues: {},
      globalValidationErrors: {},
      error: null,
      isLoading: false,
      isLoadingStore: true,
      isSubmitting: false,
      openPreviewScreen: flag,
      globalSelectedStores: [],
      selectedProduct: {
        id: undefined,
        name: undefined,
      },
    }),
  resetStore: () =>
    set({
      originalMinMaxPrice: [],
      originalAutoApproveFlagData: [],
      originalOpisAgeData: [],
      originalLastDigitNineData: [],
      originalCompBoundaryData: [],
      editedMinMaxPrice: [],
      originalMaxIncDec: [],
      editedAutoApprovalRowMeta: {},
      editedAutoApproveFlagData: [],
      editedOpisAgeData: [],
      editedCompBoundaryData: [],
      editedOpisAgeRowMeta: {},
      editedOpisAgeRowIds: [],
      editedLastDigitNineData: [],
      editedLastDigitNineRowMeta: {},
      editedLastDigitNineRowIds: [],
      editedMinMaxRowIds: [],
      editedMinMaxRowMeta: {},
      editedMaxIncDecRowMeta: {},
      editedCompBoundaryRowMeta: {},
      maxIncDecGridErrors: {},
      compBoundaryGridErrors: {},
      compBoundaryGridWarnings: {},
      editedMaxIncDec: [],
      editedMaxIncRowIds: [],
      globalMinMaxPriceValues: {},
      globalMaxIncDecValues: {},
      globalAutoApprovalValues: {},
      globalOpisAgeValues: {},
      globalLastDigitNineValues: {},
      globalValidationErrors: {},
      error: null,
      isLoading: false,
      isLoadingStore: true,
      isSubmitting: false,
      inEditMode: {},
      openPreviewScreen: false,
      globalSelectedStores: [],
      selectedProduct: {
        id: undefined,
        name: undefined,
      },
      previewScreenData: {},
    }),
  resetControlData: () =>
    set((state) => ({
      // Reset data-related states while preserving store selection and product
      originalMinMaxPrice: [],
      originalAutoApproveFlagData: [],
      editedMinMaxPrice: [],
      editedAutoApproveFlagData: [],
      originalMaxIncDec: [],
      editedMaxIncDec: [],
      editedMinMaxRowIds: [],
      editedMaxIncRowIds: [],
      editedMinMaxRowMeta: {},
      editedAutoApprovalRowMeta: {},
      editedMaxIncDecRowMeta: {},
      editedOpisAgeRowIds: [],
      editedOpisAgeRowMeta: {},
      editedLastDigitNineRowIds: [],
      editedLastDigitNineRowMeta: {},
      compBoundaryGridErrors: {},
      compBoundaryGridWarnings: {},
      globalMinMaxPriceValues: {},
      globalMaxIncDecValues: {},
      globalAutoApprovalValues: {},
      globalValidationErrors: {},
      maxIncDecGridErrors: {},
      error: null,
      isLoading: true, // Set to true as we're about to fetch new data
      isSubmitting: false,
      openPreviewScreen: false,
      previewScreenData: {},
      // Don't reset globalSelectedStores and selectedProduct
      globalSelectedStores: state.globalSelectedStores,
      selectedProduct: state.selectedProduct,
    })),
}));

export default useControlStore;
