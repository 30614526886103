import { Box, CssBaseline, Paper, ThemeProvider } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import "react-resizable/css/styles.css";
import SELoaderWithBackdrop from "../core/SELoaderWithBackDrop";
import { usePatchBulkControlConfig } from "../hooks/controlConfigApiHooks";
import { usePricegenStoreFromES } from "../hooks/elasticDataApiHooks";
import { useResizableContainerWindowSettings } from "../hooks/useResizableContainerWindowSettings";
import { IControlConfigPatchRequest } from "../interfaces/IControlConfigPatchRequest";
import { ProductInfo } from "../interfaces/IStore";
import useControlStore from "../store/useStoreControl";
import { themeDef } from "../theme/theme";
import { getUserName } from "../utils/app-utils";
import {
  mapAutoApprovalChanges,
  mapCompBoundaryChanges,
  mapLastDigitNineChanges,
  mapMaxIncDecChanges,
  mapMinMaxPriceChanges,
  mapOpisAgeChanges,
  transformControlConfigChangesList,
} from "../utils/mapper";
import AppContent from "./AppContent";
import AppHeader from "./AppHeader";
import ConfirmationModal from "./ConfirmationModal";
import EmptyStateScreen from "./EmptyStateScreen";
import PreviewComponent from "./PreviewComponent";
import ResizableDraggableContainer from "./ResizableDraggableContainer";
import StoreModal from "./StoreModal";

interface RootProps {
  listviewSelectedStores: string[];
  extractedStoreCount: number;
}

interface ControlChangesMeta {
  id: string;
  lastDigitNine: string[];
  maxIncDec: string[];
  minMaxPrice: string[];
  autoApproval: string[];
  opisAge: string[];
  compBoundary: Record<string, string[]>;
}

const RootComponent = ({ customProps }) => {
  const [rootProps, setRootProps] = useState<RootProps>();
  const [noStoreMessage, setNoStoreMessage] = useState({
    title: "",
    body: "",
  });
  const [isStoreModalOpen, setStoreModalOpen] = useState(false);
  const [isUnsavedChanges, setIsUnsavedChanges] = useState(false);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [size, setSize] = useState<{ width: number; height: number }>({
    width: 1200,
    height: 180, // Initial compact height
  });
  const [isMaximized, setIsMaximized] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<ProductInfo>();
  const { handleOnDrag, position, toggleFullScreen } =
    useResizableContainerWindowSettings();
  const { mutate } = usePatchBulkControlConfig();
  const { enqueueSnackbar } = useSnackbar();
  const {
    isSubmitting,
    editedMaxIncDecRowMeta,
    editedMinMaxRowMeta,
    editedAutoApprovalRowMeta,
    editedOpisAgeRowMeta,
    editedLastDigitNineRowMeta,
    editedMinMaxPrice,
    editedAutoApproveFlagData,
    editedOpisAgeData,
    editedLastDigitNineData,
    editedMaxIncDec,
    editedCompBoundaryRowMeta,
    editedCompBoundaryData,
    setIsSubmitting,
    setPreviewScreenData,
    openPreviewScreen,
    setOpenPreviewScreen,
    resetStore,
    globalSelectedStores,
    setGlobalSelectedStores,
  } = useControlStore();
  const { data, error, isLoading } = usePricegenStoreFromES();

  const toggleMaximize = () => {
    if (!isMaximized) {
      setSize({
        width: window.innerWidth - 45,
        height: window.innerHeight - 155,
      });
      toggleFullScreen(!isMaximized);
    } else {
      setSize({ width: 1200, height: 780 });
      toggleFullScreen(!isMaximized);
    }
    setIsMaximized(!isMaximized);
  };

  useEffect(() => {
    const subscription =
      customProps?.compRef?.mapLayerService?.openMultiStoreControlConfigWindow$?.subscribe(
        (data: RootProps) => {
          console.log("MSE --> ", data);

          setRootProps(data);
        }
      );
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    // Log to debug
    console.log("Selected Product:", selectedProduct);

    if (selectedProduct?.id) {
      // Check for specific property to ensure product is selected
      setSize((prev) => ({ ...prev, height: 780 }));
    } else {
      setSize((prev) => ({ ...prev, height: 180 }));
    }
  }, [selectedProduct]);

  // Function to merge and flatten the changes by ID
  const mergeAndFlattenChanges = (
    lastDigitNine,
    minMaxPrice,
    maxIncDec,
    autoApproval,
    opisAge,
    compBoundary
  ): ControlChangesMeta[] => {
    const changeTypes = [
      { name: "lastDigitNine", data: lastDigitNine },
      { name: "minMaxPrice", data: minMaxPrice },
      { name: "maxIncDec", data: maxIncDec },
      { name: "autoApproval", data: autoApproval },
      { name: "opisAge", data: opisAge },
    ] as const;

    // Initialize result map for easier lookup
    const resultMap = new Map<string, ControlChangesMeta>();

    // Process regular change types
    changeTypes.forEach(({ name, data }) => {
      Object.entries(data).forEach(([id, fields]) => {
        if (!resultMap.has(id)) {
          resultMap.set(id, {
            id: id,
            lastDigitNine: [],
            minMaxPrice: [],
            maxIncDec: [],
            autoApproval: [],
            opisAge: [],
            compBoundary: {},
          });
        }

        const entry = resultMap.get(id)!;
        entry[name] = fields as string[];
      });
    });

    // Process compBoundary changes separately
    Object.entries(compBoundary).forEach(([compositeId, fields]) => {
      const [storeId, opisId] = compositeId.split("_");

      if (!resultMap.has(storeId)) {
        resultMap.set(storeId, {
          id: storeId,
          lastDigitNine: [],
          minMaxPrice: [],
          maxIncDec: [],
          autoApproval: [],
          opisAge: [],
          compBoundary: {},
        });
      }

      const entry = resultMap.get(storeId)!;
      entry.compBoundary[opisId] = fields as string[];
    });

    return Array.from(resultMap.values());
    // const result = [];

    // // Add lastDigitNine changes to the result
    // for (const [id, fields] of Object.entries(lastDigitNine)) {
    //   let existingEntry = result.find((entry) => entry.id === id);
    //   if (!existingEntry) {
    //     existingEntry = {
    //       id,
    //       lastDigitNine: [],
    //       minMaxPrice: [],
    //       maxIncDec: [],
    //       autoApproval: [],
    //       opisAge: [],
    //       compBoundary: [],
    //     };
    //     result.push(existingEntry);
    //   }
    //   existingEntry.lastDigitNine = fields;
    // }

    // // Add minMaxPrice changes to the result
    // for (const [id, fields] of Object.entries(minMaxPrice)) {
    //   let existingEntry = result.find((entry) => entry.id === id);
    //   if (!existingEntry) {
    //     existingEntry = {
    //       id,
    //       lastDigitNine: [],
    //       minMaxPrice: [],
    //       maxIncDec: [],
    //       autoApproval: [],
    //       opisAge: [],
    //       compBoundary: [],
    //     };
    //     result.push(existingEntry);
    //   }
    //   existingEntry.minMaxPrice = fields;
    // }

    // // Add maxIncDec changes to the result
    // for (const [id, fields] of Object.entries(maxIncDec)) {
    //   let existingEntry = result.find((entry) => entry.id === id);
    //   if (!existingEntry) {
    //     existingEntry = {
    //       id,
    //       lastDigitNine: [],
    //       minMaxPrice: [],
    //       maxIncDec: [],
    //       autoApproval: [],
    //       opisAge: [],
    //       compBoundary: [],
    //     };
    //     result.push(existingEntry);
    //   }
    //   existingEntry.maxIncDec = fields;
    // }

    // // Add autoApproval changes to the result
    // for (const [id, fields] of Object.entries(autoApproval)) {
    //   let existingEntry = result.find((entry) => entry.id === id);
    //   if (!existingEntry) {
    //     existingEntry = {
    //       id,
    //       lastDigitNine: [],
    //       minMaxPrice: [],
    //       maxIncDec: [],
    //       autoApproval: [],
    //       opisAge: [],
    //       compBoundary: [],
    //     };
    //     result.push(existingEntry);
    //   }
    //   existingEntry.autoApproval = fields;
    // }

    // // Add opisAge changes to the result
    // for (const [id, fields] of Object.entries(opisAge)) {
    //   let existingEntry = result.find((entry) => entry.id === id);
    //   if (!existingEntry) {
    //     existingEntry = {
    //       id,
    //       lastDigitNine: [],
    //       minMaxPrice: [],
    //       maxIncDec: [],
    //       autoApproval: [],
    //       opisAge: [],
    //       compBoundary: [],
    //     };
    //     result.push(existingEntry);
    //   }
    //   existingEntry.opisAge = fields;
    // }

    // // Add comp boundary changes to the result
    // for (const [id, fields] of Object.entries(editedCompBoundary)) {
    //   let existingEntry = result.find((entry) => entry.id === id);
    //   if (!existingEntry) {
    //     existingEntry = {
    //       id,
    //       lastDigitNine: [],
    //       minMaxPrice: [],
    //       maxIncDec: [],
    //       autoApproval: [],
    //       opisAge: [],
    //       compBoundary: [],
    //     };
    //     result.push(existingEntry);
    //   }
    //   existingEntry.compBoundary = fields;
    // }

    // return result;
  };
  const patchControlConfigChanges = () => {
    setConfirmationOpen(false);
    setIsSubmitting(true);
    const changesMeta: ControlChangesMeta[] = mergeAndFlattenChanges(
      editedLastDigitNineRowMeta,
      editedMinMaxRowMeta,
      editedMaxIncDecRowMeta,
      editedAutoApprovalRowMeta,
      editedOpisAgeRowMeta,
      editedCompBoundaryRowMeta
    );

    const finalControlPatchObject: IControlConfigPatchRequest[] =
      changesMeta.map((change) => {
        const {
          id,
          lastDigitNine,
          maxIncDec,
          minMaxPrice,
          autoApproval,
          opisAge,
          compBoundary,
        } = change;

        const requestObject: IControlConfigPatchRequest = {
          storeId: undefined,
          productId: selectedProduct?.id,
          lastUpdatedBy: getUserName(),
        };
        if (maxIncDec && maxIncDec.length) {
          const editedRow = editedMaxIncDec.find((row) => row.id === id);
          if (!requestObject.storeId) {
            requestObject.storeId = editedRow.store;
          }
          const changedObj = {
            action_on_failure_new: undefined,
            enabled_new: undefined,
            max_increase_new: undefined,
            max_decrease_new: undefined,
          };
          maxIncDec.forEach((field) => (changedObj[field] = editedRow[field]));
          const mappedMaxIncDecChanges = mapMaxIncDecChanges(changedObj);
          requestObject.maxIncDecControlConfigs = mappedMaxIncDecChanges;
        }

        if (minMaxPrice && minMaxPrice.length) {
          const editedRow = editedMinMaxPrice.find((row) => row.id === id);
          if (!requestObject.storeId) {
            requestObject.storeId = editedRow.store;
          }
          const changedObj = {
            action_on_failure_new: undefined,
            enabled_new: undefined,
            min_price_new: undefined,
            max_price_new: undefined,
          };
          minMaxPrice.forEach(
            (field) => (changedObj[field] = editedRow[field])
          );
          const mappedMinMaxChanges = mapMinMaxPriceChanges(changedObj);
          requestObject.minMaxPriceControlConfigs = mappedMinMaxChanges;
        }

        if (autoApproval && autoApproval.length) {
          const editedRow = editedAutoApproveFlagData.find(
            (row) => row.id === id
          );
          if (!requestObject.storeId) {
            requestObject.storeId = editedRow.store;
          }

          const changedObj = {
            enabled_new: undefined,
          };
          autoApproval.forEach(
            (field) => (changedObj[field] = editedRow[field])
          );
          requestObject.autoApprove = mapAutoApprovalChanges(changedObj);
        }

        if (opisAge && opisAge.length) {
          const editedRow = editedOpisAgeData.find((row) => row.id === id);
          if (!requestObject.storeId) {
            requestObject.storeId = editedRow.store;
          }
          const changedObj = {
            action_on_failure_new: undefined,
            enabled_new: undefined,
            opis_age_new: undefined,
          };
          opisAge.forEach((field) => (changedObj[field] = editedRow[field]));
          const mappedOpisAgeChanges = mapOpisAgeChanges(changedObj);
          requestObject.opisAgeControlConfigs = mappedOpisAgeChanges;
        }

        if (lastDigitNine && lastDigitNine.length) {
          const editedRow = editedLastDigitNineData.find(
            (row) => row.id === id
          );
          if (!requestObject.storeId) {
            requestObject.storeId = editedRow.store;
          }
          const changedObj = {
            action_on_failure_new: undefined,
            enabled_new: undefined,
          };
          lastDigitNine.forEach(
            (field) => (changedObj[field] = editedRow[field])
          );
          const mappedLastDigitNineChanges =
            mapLastDigitNineChanges(changedObj);
          requestObject.lastDigitNineControlConfigs =
            mappedLastDigitNineChanges;
        }

        if (compBoundary && Object.keys(compBoundary).length > 0) {
          if (!requestObject.storeId) {
            requestObject.storeId = id;
          }

          const changedObjList = Object.entries(compBoundary).map(
            ([opisId, fields]) => {
              const editedRow = editedCompBoundaryData.find(
                (row) => row.store == id && row.opisId == opisId
              );

              return mapCompBoundaryChanges(fields, editedRow, opisId);
            }
          );
          requestObject.compBoundaryConfigs = {
            validationType: "CompetitorBoundary",
            competitorBoundary: changedObjList,
          };
        }

        return requestObject;
      });
    console.log(finalControlPatchObject);

    mutate(finalControlPatchObject, {
      onSuccess: (data) => {
        const previewScreenData = transformControlConfigChangesList(data);

        enqueueSnackbar("Control configs updated successfully", {
          variant: "success",
          anchorOrigin: { horizontal: "right", vertical: "top" },
          autoHideDuration: 6000,
        });
        setPreviewScreenData(previewScreenData);
        setIsSubmitting(false);
        setOpenPreviewScreen(true);
      },
      onError(error, variables, context) {
        console.error(error);
        enqueueSnackbar(
          "Unable to apply the bulk configuration updates. Please check for any issues and try again.",
          {
            variant: "error",
            anchorOrigin: { horizontal: "right", vertical: "top" },
            autoHideDuration: 6000,
          }
        );
      },
    });
  };

  useEffect(() => {
    if (selectedProduct) {
      setSize((prev) => ({ ...prev, height: 780 }));
    } else {
      setSize((prev) => ({ ...prev, height: 300 }));
    }
  }, [selectedProduct]);

  // const hasSelectedStores = rootProps?.listviewSelectedStores?.length > 0;
  useEffect(() => {
    console.log(
      "List view selected stores --> ",
      rootProps?.listviewSelectedStores
    );

    if (rootProps?.listviewSelectedStores?.length <= 0) {
      setNoStoreMessage({
        title: "No Stores Selected from list",
        body: "Please select one or more PriceGen stores to configure control settings",
      });
    } else {
      if (
        data &&
        rootProps?.listviewSelectedStores &&
        rootProps?.listviewSelectedStores?.length > 0
      ) {
        console.log("ES Data -- ", data);
        console.log("Root props --> ", rootProps);

        const filteredStores = data.filter(
          (store) =>
            rootProps?.listviewSelectedStores?.includes(store.importcode) &&
            store.seiid
        );
        console.log(filteredStores);

        if (filteredStores?.length > 0) {
          setGlobalSelectedStores(filteredStores);
        } else {
          setNoStoreMessage({
            title: "No PriceGen stores selected",
            body: "Please select one or more PriceGen stores to configure control settings",
          });
        }
      }
    }
  }, [rootProps?.listviewSelectedStores, data]);

  return (
    <>
      <ThemeProvider theme={themeDef}>
        <CssBaseline />
        <ResizableDraggableContainer
          size={size}
          setSize={setSize}
          minConstraints={[1200, selectedProduct?.id ? 780 : 180]}
          maxConstraints={[window.innerWidth - 50, window.innerHeight - 50]}
          position={position}
          onDrag={handleOnDrag}
        >
          <Paper
            style={{
              position: "absolute",
              flexGrow: 1,
              overflow: "visible",
              display: "flex",
              flexDirection: "column",
              height: size.height,
              width: size.width,
              cursor: "default",
              borderRadius: "8px",
              boxShadow: "0 2px 10px rgba(0,0,0,0.12), 0 0 1px rgba(0,0,0,0.1)",
              transition: "height 0.3s ease-in-out",
            }}
          >
            <Box className="draggable-handle">
              <AppHeader
                toggleMaximize={toggleMaximize}
                isMaximized={isMaximized}
                openStoreModal={setStoreModalOpen}
                setConfirmationOpen={setConfirmationOpen}
                showChips={!openPreviewScreen}
                closeApp={() => {
                  resetStore();
                  customProps.compRefClose();
                }}
                setIsUnsavedChanges={setIsUnsavedChanges}
              />
            </Box>
            {!(globalSelectedStores.length > 0) && !openPreviewScreen ? (
              <>
                <SELoaderWithBackdrop open={isLoading} />
                <EmptyStateScreen
                  openStoreModal={() => setStoreModalOpen(true)}
                  noStoreMessage={noStoreMessage}
                />
              </>
            ) : openPreviewScreen ? (
              <Box
                sx={{
                  flex: 1,
                  overflow: "auto",
                  padding: "16px",
                  "& > .MuiStack-root": {
                    padding: "0 8px",
                    marginBottom: "16px",
                  },
                }}
              >
                <PreviewComponent />
              </Box>
            ) : (
              <>
                <SELoaderWithBackdrop open={isSubmitting} />
                <Box
                  sx={{
                    flex: 1,
                    overflow: "hidden",
                    padding: "16px",
                    "& > .MuiStack-root": {
                      padding: "0 8px",
                      marginBottom: "16px",
                    },
                  }}
                >
                  <AppContent
                    selectedStoreIdList={rootProps?.listviewSelectedStores}
                    isMaximized={isMaximized}
                    selectedProduct={selectedProduct}
                    setSelectedProduct={setSelectedProduct}
                  />
                </Box>
              </>
            )}
          </Paper>
        </ResizableDraggableContainer>
        <StoreModal isOpen={isStoreModalOpen} handleClose={setStoreModalOpen} />
        <ConfirmationModal
          isOpen={isConfirmationOpen}
          setConfirmationOpen={setConfirmationOpen}
          patchControlConfigChanges={patchControlConfigChanges}
          isUnsavedChanges={isUnsavedChanges}
        />
      </ThemeProvider>
    </>
  );
};

export default RootComponent;
