import { AxiosError } from "axios";
import {
  getMultiStoreSingleProductControl,
  getMultiStoreSingleProductControlV2,
} from "../api/controlConfigApi";
import { patchControlsForMultipleStores } from "../api/patchControlsApi";
import {
  MultiStoreControlConfig,
  MultiStoreSingleProductReq,
} from "../interfaces/IControlConfig";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { IControlConfigPatchRequest } from "../interfaces/IControlConfigPatchRequest";
import {
  IControlConfigRequest,
  IStoreDetails,
} from "../interfaces/IControlConfigRequest";

export const useGetMultiStoreSingleProductControl = (
  stores: string[],
  product: string
) => {
  return useQuery<MultiStoreControlConfig, Error>(
    ["multiStoreControl", stores, product],
    () => {
      if (stores?.length && product) {
        const reqObj: MultiStoreSingleProductReq = {
          stores,
          product,
        };
        return getMultiStoreSingleProductControl(reqObj);
      }
      return null;
    },
    {
      enabled: Boolean(stores?.length && product),
      keepPreviousData: false,
      // staleTime: 30000,
      refetchOnWindowFocus: false,
      retry: 2,
      onError: (error) => {
        console.error("Error fetching control data:", error);
      },
    }
  );
};

export const usePatchBulkControlConfig = () => {
  return useMutation({
    mutationFn: (data: IControlConfigPatchRequest[]) =>
      patchControlsForMultipleStores(data),
    onSuccess: () => {
      // todo - update data and loading state
    },
    onError: (error: AxiosError) => {
      // todo stop loading and show snackbar
    },
  });
};

export const useGetMultiStoreSingleProductControlV2 = (
  stores: IStoreDetails[],
  product: string
) => {
  return useQuery<MultiStoreControlConfig, Error>(
    ["multiStoreControl", stores, product],
    () => {
      if (stores?.length && product) {
        const reqObj: IControlConfigRequest = {
          stores,
          product,
        };
        return getMultiStoreSingleProductControlV2(reqObj);
      }
      return null;
    },
    {
      enabled: Boolean(stores?.length && product),
      keepPreviousData: false,
      // staleTime: 30000,
      refetchOnWindowFocus: false,
      retry: 2,
      onError: (error) => {
        console.error("Error fetching control data:", error);
      },
    }
  );
};
