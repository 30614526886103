import constants from "../constants/constants.json";

export function getChangedProperties(newRow: any, originalRow: any): string[] {
  const changedProperties: string[] = [];

  // Iterate over the keys of the newRow
  for (const key in newRow) {
    // Check if the key exists in originalRow and the values are different
    if (newRow.hasOwnProperty(key) && newRow[key] != originalRow[key]) {
      changedProperties.push(key);
    }
  }

  return changedProperties;
}

export function getUserName() {
  return sessionStorage.getItem(constants.USER_NAME_KEY) || "UNKNOWN USER";
}

export function mergeIfDifferent(obj1, obj2) {
  const merged = { ...obj1 }; // Start with a copy of obj1

  for (const key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      const value1 = obj1[key];
      const value2 = obj2[key];

      // Normalize values to the same type (number for this example)
      const normalizedValue1 =
        typeof value1 === "string" ? parseFloat(value1) : value1;
      const normalizedValue2 =
        typeof value2 === "string" ? parseFloat(value2) : value2;

      // Merge only if values are different after normalization
      if (normalizedValue1 !== normalizedValue2) {
        merged[key] = obj2[key];
      }
    }
  }

  return merged;
}

export const validateCompetitorBoundaryRange = (data, field, value) => {
  const { comp_price, own_price, upper_threshold_new, lower_threshold_new } =
    data || {};

  // Convert price strings to float values, ignore "N/A"
  const parsePrice = (price) =>
    price && price !== "N/A"
      ? parseFloat(price.replace(/[^0-9.-]/g, "")) || 0
      : null;

  const compPrice = parsePrice(comp_price);
  if (!compPrice) return;
  const ownPrice = parsePrice(own_price);

  // If field is null, ignore the value parameter and use existing thresholds
  const upperThreshold =
    field === "upper_threshold_new"
      ? parsePrice(value)
      : field === null
      ? parsePrice(upper_threshold_new)
      : parsePrice(upper_threshold_new);

  const lowerThreshold =
    field === "lower_threshold_new"
      ? parsePrice(value)
      : field === null
      ? parsePrice(lower_threshold_new)
      : parsePrice(lower_threshold_new);

  // Skip validation if both thresholds are missing or "N/A"
  if (upperThreshold === null && lowerThreshold === null) return "";

  // Convert cents (¢) to dollars ($) (ignore null thresholds)
  const lowerLimit =
    lowerThreshold !== null ? compPrice + lowerThreshold / 100 : null;
  const upperLimit =
    upperThreshold !== null ? compPrice + upperThreshold / 100 : null;

  // If own price is within range, return an empty string (valid case)
  if (
    (lowerLimit === null || ownPrice >= lowerLimit) &&
    (upperLimit === null || ownPrice <= upperLimit)
  )
    return "";

  let message;
  // Construct the message based on whether ownPrice is too high or too low
  if (upperLimit !== null && ownPrice > upperLimit) {
    message = `Last proposed price ($${ownPrice.toFixed(
      3
    )}) exceeds the upper limit! Expected range: [${
      lowerLimit !== null ? `$${lowerLimit.toFixed(3)}` : "No lower limit"
    } - $${upperLimit.toFixed(3)}]`;
  } else if (lowerLimit !== null && ownPrice < lowerLimit) {
    message = `Last proposed price ($${ownPrice.toFixed(
      3
    )}) falls below the lower limit! Expected range: [$${lowerLimit.toFixed(
      3
    )} - ${
      upperLimit !== null ? `$${upperLimit.toFixed(3)}` : "No upper limit"
    }]`;
  }

  return message;
};
